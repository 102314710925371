import { generateAttendeeFormSchema } from "helpers/form";
import FormTemplate, {
  AttendeeModalFormTemplate,
  componentMapper
} from "components/form/FormTemplate";
import { FormRenderer } from "@data-driven-forms/react-form-renderer";
import useUserSaveAttendee from "hooks/useUserSaveAttendee";
import type { Attendee } from "types/model/attendee";
import type { Field } from "types/model/field";
import type { Client } from "types/model/client";

interface EditAttendeeFormProps {
  activityGroupId?: string;
  attendee: Attendee;
  attendeeFields: Field[];
  client: Client;
  isInModal?: boolean;
  onCreate?: () => void;
  handleSubmit?: (formData) => void;
  onCancel: () => void;
}

const EditAttendeeForm = ({
  activityGroupId,
  attendee,
  attendeeFields,
  client,
  isInModal = false,
  handleSubmit,
  onCreate,
  onCancel
}: EditAttendeeFormProps): React.ReactElement => {
  const [saveUserAttendee] = useUserSaveAttendee();
  const formSchema = generateAttendeeFormSchema({
    fieldsData: attendeeFields,
    attendeeData: attendee.fieldData,
    activityGroupId,
    assignToUser: false,
    isAdminUse: false,
    client
  });

  const onSubmit = async (formData): Promise<void> => {
    await saveUserAttendee({ id: attendee._id, formData });
    onCreate();
  };

  const handleOnCancel = (): void => {
    onCancel();
  };

  return (
    <FormRenderer
      componentMapper={componentMapper}
      FormTemplate={isInModal ? AttendeeModalFormTemplate : FormTemplate}
      schema={formSchema}
      clearedValue={null}
      onSubmit={handleSubmit || onSubmit}
      onCancel={handleOnCancel}
    />
  );
};

export default EditAttendeeForm;
