import { generateAttendeeFormSchema } from "helpers/form";
import FormTemplate, {
  AttendeeModalFormTemplate,
  componentMapper
} from "components/form/FormTemplate";
import { FormRenderer } from "@data-driven-forms/react-form-renderer";
import useCreateCurrentUserAttendee from "hooks/useCreateCurrentUserAttendee";
import type { Field } from "types/model/field";
import type { Client } from "types/model/client";

interface NewAttendeeFormProps {
  lineItemId?: string;
  activityGroupId?: string;
  attendeeFields: Field[];
  client: Client;
  isInModal?: boolean;
  onCreate?: (lineItemId: string, attendeeId: string) => void;
  handleSubmit?: (formData) => void;
  onCancel: () => void;
}

const NewAttendeeForm = ({
  lineItemId,
  activityGroupId,
  attendeeFields,
  client,
  isInModal = false,
  handleSubmit,
  onCreate,
  onCancel
}: NewAttendeeFormProps): React.ReactElement => {
  const [createCurrentUserAttendee] = useCreateCurrentUserAttendee();
  const formSchema = generateAttendeeFormSchema({
    fieldsData: attendeeFields,
    attendeeData: [],
    assignToUser: false,
    isAdminUse: false,
    activityGroupId,
    client
  });

  const onSubmit = async (formData): Promise<void> => {
    const response = await createCurrentUserAttendee(formData);
    const newAttendeeId = response.data._id;
    onCreate(lineItemId, newAttendeeId);
  };

  const handleOnCancel = (): void => {
    onCancel();
  };

  return (
    <FormRenderer
      componentMapper={componentMapper}
      FormTemplate={isInModal ? AttendeeModalFormTemplate : FormTemplate}
      schema={formSchema}
      onSubmit={handleSubmit || onSubmit}
      onCancel={handleOnCancel}
    />
  );
};

export default NewAttendeeForm;
