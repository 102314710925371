import type { AxiosError, AxiosResponse } from "axios";
import axios from "axios";
import { getShouldNotRetry } from "helpers/auth";
import type { QueryResult} from "react-query";
import { useQuery } from "react-query";
import type { ErrorResponse } from "types";
import type { ActivityGroupFullListItem } from "types/model/activity-group";
import handleRequestError from "./helpers/handleRequestError";

export const getActivityGroupsFullList = async (
  _,
  filters?: string
): Promise<ActivityGroupFullListItem[]> => {
  const response: AxiosResponse<ActivityGroupFullListItem[]> = await axios.get(
    `/api/activity-groups/full-list${filters ? `?filters=${filters}` : ""}`
  );
  return response.data;
};

export default function useActivityGroupsFullList(
  filters?: string
): QueryResult<ActivityGroupFullListItem[], AxiosError<ErrorResponse>> {
  return useQuery(
    ["activityGroupsFullList", filters],
    getActivityGroupsFullList,
    {
      retry: (failureCount: number, error) => {
        return getShouldNotRetry(error.response?.status)
          ? false
          : failureCount < 3;
      },
      onError: error => {
        handleRequestError(error);
      }
    }
  );
}
