import { compareAsc } from "date-fns";
import type { AddOnWithActivity} from "types/model/activity";
import { TicketType } from "types/model/activity";
import type {
  CreateAdminBookingFormData,
  CreateAdminBookingFormDataToSubmit,
  CreateAdminBookingItemsByActivityGroup
} from "types/model/admin-booking";
import type { Field } from "types/model/field";
import { getIsFieldApplicableToActivityGroup } from "./field";
import type { Attendee } from "types/model/attendee";

export const convertCreateAdminBookingFormDataToSubmit = (
  formData: CreateAdminBookingFormData
): CreateAdminBookingFormDataToSubmit => {
  const data = {
    userId: formData.userId,
    bookingItems: formData.bookingItems.map(activityGroup => {
      return {
        activityGroupId: activityGroup.activityGroup._id,
        items: activityGroup.items.map(item => {
          return {
            ticketId: item.ticket._id,
            attendeeIds: item.attendees.map(attendee => attendee._id),
            ...(item.ticket.type === TicketType.Single && {
              activityId: item.activities[0]._id,
              addOnItems: item.addOns ? item.addOns.map(addOn => addOn._id) : []
            }),
            ...(item.ticket.type === TicketType.All && {
              addOnItems: item.addOns
                ? item.addOns.map(addOn => `${addOn.activity._id}_${addOn._id}`)
                : []
            })
          };
        })
      };
    })
  };

  return data;
};

export const getAreAddOnItemsEqual = (
  one: AddOnWithActivity[] = [],
  two: AddOnWithActivity[] = [],
  ticketType: TicketType
) => {
  if (one.length !== two.length) {
    return false;
  }

  if (ticketType === TicketType.Single) {
    return one.every((addOn, index) => addOn._id === two[index]._id);
  }

  return one.every(
    (addOn, index) =>
      addOn._id === two[index]._id &&
      addOn.activity._id === two[index].activity._id
  );
};

export const getSortedCreateAdminBookingItemsByActivityGroup = (
  items: CreateAdminBookingItemsByActivityGroup[]
) => {
  const sortedItems = items.map(activityGroupItem => {
    return {
      activityGroup: activityGroupItem.activityGroup,
      items: activityGroupItem.items
        .sort((a, b) => {
          return a.ticket.type === TicketType.All ? -1 : 1;
        })
        .sort((a, b) => {
          const activityA = a.activities[0];
          const activityB = b.activities[0];
          return compareAsc(
            new Date(activityA.date.start),
            new Date(activityB.date.start)
          );
        })
        .sort((a, b) => {
          const aAddOnsLength = a.addOns?.length || 0;
          const bAddOnsLength = b.addOns?.length || 0;
          return aAddOnsLength - bAddOnsLength;
        })
    };
  });

  return sortedItems;
};

export const getAreRequiredFieldsCompleted = (
  attendee: Attendee,
  attendeeFields: Field[],
  activityGroupId: string
): boolean => {
  const requiredAttendeeFields = attendeeFields.filter(
    field =>
      field.required &&
      getIsFieldApplicableToActivityGroup(field, activityGroupId)
  );

  const areAllRequiredFieldsCompleted = requiredAttendeeFields.every(field => {
    return attendee.fieldData.some(
      fieldDataItem => fieldDataItem.field?._id === field._id
    );
  });

  return areAllRequiredFieldsCompleted;
};
