import type { Client } from "types/model/client";
import { formatDate } from "./date";
import type { TableColumn, TableData } from "types/model/table";
import type {
  AccountCreditExpiresAfter,
  AccountCreditItem,
  AccountCreditItemWithUsedRemainingAmounts
} from "types/model/account-credit";
import {
  AccountCreditItemStatus
} from "types/model/account-credit";
import { formatCurrency } from "./helpers";
import { isBefore } from "date-fns";

interface GetAccountCreditItemsTableData {
  data: AccountCreditItemWithUsedRemainingAmounts[];
  client: Client;
}

export const getUserAccountCreditItemsTableData = ({
  data,
  client
}: GetAccountCreditItemsTableData): TableData => {
  const formattedData = data.map(item => {
    return {
      created: formatDate(item.created, "d MMM yyyy, h.mmaaaa", client.timeZone)
        .replace("a.m.", "am")
        .replace("p.m.", "pm"),
      creditAmount: formatCurrency({
        rawAmount: item.amount,
        currency: client.currency
      }),
      amountUsed: {
        type: "html",
        value: item.debitItems.length
          ? `<a class="font-medium text-indigo-600 hover:text-indigo-900 focus:outline-none focus:underline cursor-pointer">${formatCurrency(
              {
                rawAmount: item.amountUsed,
                currency: client.currency
              }
            )}</a>`
          : "None",
        clickAction: "showDebitItemsModal"
      },
      amountRemaining: formatCurrency({
        rawAmount: item.amountRemaining,
        currency: client.currency
      }),
      debitItems: item.debitItems,
      expiry: item.expires
        ? formatDate(item.expires, "d MMM yyyy", client.timeZone)
            .replace("a.m.", "am")
            .replace("p.m.", "pm")
        : "No expiry",
      status: getReadableAccountCreditItemStatus(item.accountCreditItemStatus),
      item,
      deductCredit: {
        type: "html",
        value:
          item.accountCreditItemStatus == AccountCreditItemStatus.Active
            ? `<a class="font-medium text-indigo-600 hover:text-indigo-900 focus:outline-none focus:underline cursor-pointer">Deduct credit</a>`
            : "",
        clickAction: "showDeductCreditModal"
      },
      enabled: true // prevent lower opacity for disabled rows
    };
  });

  return formattedData;
};

export const getUserAccountCreditItemsColumnHeaders = (): TableColumn[] => {
  const columnHeaders = [
    {
      Header: "Created",
      accessor: "created"
    },

    {
      Header: "Credit amount",
      accessor: "creditAmount"
    },
    {
      Header: "Amount used",
      accessor: "amountUsed" // link to debit items
    },
    {
      Header: "Amount remaining",
      accessor: "amountRemaining"
    },
    {
      Header: "Expiry",
      accessor: "expiry"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "",
      accessor: "deductCredit"
    }
  ];

  return columnHeaders;
};

export const getAccountCreditItemStatus = (
  accountCreditItem: AccountCreditItem<
    string,
    string,
    AccountCreditItem<unknown, unknown, unknown>
  >
) => {
  const amountUsed = accountCreditItem.debitItems.reduce(
    (acc, item) => (acc += item.amount),
    0
  );
  const amountUsedAsPositiveValue = -1 * amountUsed;

  if (accountCreditItem.amount <= amountUsedAsPositiveValue) {
    return AccountCreditItemStatus.FullyUsed;
  } else if (isBefore(new Date(accountCreditItem.expires), new Date())) {
    return AccountCreditItemStatus.Expired;
  }
  return AccountCreditItemStatus.Active;
};

export const getReadableAccountCreditItemStatus = (
  status: AccountCreditItemStatus
) => {
  if (status === AccountCreditItemStatus.Active) {
    return "Active";
  } else if (status === AccountCreditItemStatus.Expired) {
    return "Expired";
  } else if (status === AccountCreditItemStatus.FullyUsed) {
    return "Fully used";
  }
  return status;
};

export const getAccountCreditExpiresReadableValue = (
  accountCreditExpiresAfter: AccountCreditExpiresAfter
): string => {
  // Check if the amount is 1; if so, remove the trailing 's' from the unit
  const unit =
    accountCreditExpiresAfter.amount === 1
      ? accountCreditExpiresAfter.unit.toString().slice(0, -1)
      : accountCreditExpiresAfter.unit.toString();

  // Return the readable string
  return `${accountCreditExpiresAfter.amount} ${unit}`;
};
