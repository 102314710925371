import { useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { cn } from "utils/cn";
import { kebabCase } from "lodash";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import { FormTemplateType } from "types/form";
import HelpText from "../HelpText";
import { CloudArrowUpIcon } from "@heroicons/react/24/outline";

const ImageField = (props): React.ReactElement => {
  const {
    label,
    input,
    isRequired,
    index,
    arrayField,
    helpText,
    formTemplate = FormTemplateType.Default
  } = useFieldApi(props);

  const isDefaultFormTemplate = formTemplate === FormTemplateType.Default;
  const isSeamlessFormTemplate = formTemplate === FormTemplateType.Seamless;
  const isFirstItem = index === 0;

  const [isUploading, setIsUploading] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const onDrop = async (acceptedFiles: File[]): Promise<void> => {
    if (!acceptedFiles.length) {
      setErrMsg("Unable to upload this file");
      return;
    }
    setErrMsg("");
    setIsUploading(true);
    const data = new FormData();
    data.append(input.name, acceptedFiles[0]);
    try {
      const response = await axios.post(
        `/api/file-uploads/${kebabCase(input.name)}`,
        data
      );
      input.onChange(response.data);
      setIsUploading(false);
    } catch (error) {
      console.log("ERROR__", error);
      setErrMsg(error?.response?.data?.message || "Error uploading file");
      setIsUploading(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "image/gif": [".gif"]
    },
    maxSize: 10485760,
    multiple: false
  });

  const removeImage = (): void => {
    input.onChange(undefined);
  };

  return (
    <div
      className={cn(
        isDefaultFormTemplate &&
          "sm:grid sm:grid-cols-3 sm:items-start sm:gap-4",
        isDefaultFormTemplate &&
          !arrayField &&
          !isFirstItem &&
          "mt-5 border-t border-gray-200 pt-5",
        (isSeamlessFormTemplate || arrayField) && !isFirstItem && "mt-5"
      )}
    >
      <label
        className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
        htmlFor={input.name}
      >
        {label}
        {isRequired && "*"}
      </label>

      <div className="mt-1 sm:col-span-2 sm:max-w-sm">
        {input.value ? (
          <>
            {input.name === "coverImage" ? (
              <div className="col-span-1 bg-gray-50 bg-center px-2 py-2">
                <div
                  className="h-24 bg-cover bg-center"
                  style={{
                    backgroundImage: `url(${input.value.path.replace(
                      "/upload/",
                      "/upload/b_rgb:000000,c_scale,f_auto,o_70,w_1500/"
                    )})`
                  }}
                ></div>
              </div>
            ) : (
              <div className="col-span-1 flex justify-center bg-gray-50 px-2 py-2">
                <img // eslint-disable-line @next/next/no-img-element
                  className="max-h-24"
                  src={input.value.path}
                  alt={input.label}
                />
              </div>
            )}
            <a
              href="#0"
              onClick={removeImage}
              className="cursor-pointer text-sm font-medium text-indigo-600 hover:text-indigo-900 focus:underline focus:outline-none"
            >
              Remove
            </a>
          </>
        ) : (
          <>
            {errMsg && (
              <div className="mb-2 rounded-md bg-red-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg
                      className="h-5 w-5 text-red-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">
                      {errMsg}
                    </h3>
                  </div>
                </div>
              </div>
            )}

            <div
              {...getRootProps()}
              className={cn(
                "flex max-w-lg justify-center rounded-md border-2 border-gray-300 px-6 pb-6 pt-5 focus:border-indigo-500 focus:ring-indigo-500",
                !isDragActive && "border-dashed"
              )}
            >
              <div className="relative space-y-1 text-center">
                {isUploading && (
                  <svg
                    className={cn(
                      "absolute inset-0 m-auto h-10 w-10 animate-spin text-indigo-600"
                    )}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
                <div className={cn(isUploading && "invisible")}>
                  <input {...getInputProps()} />
                  <CloudArrowUpIcon className="mx-auto h-12 w-12 text-gray-400" />
                  <div className="flex text-sm text-gray-600">
                    <p className="pl-1">Click to upload or drag and drop</p>
                  </div>
                  <p className="text-xs text-gray-500">
                    PNG, JPG, GIF up to 4.5MB
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
        {helpText && <HelpText label={label} helpText={helpText} />}
      </div>
    </div>
  );
};

export default ImageField;
