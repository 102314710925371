import type { AxiosError, AxiosResponse } from "axios";
import axios from "axios";
import type { WaitlistResponse } from "features/waitlists/types";
import type { QueryConfig, QueryResult } from "react-query";
import { useQuery } from "react-query";
import type { ErrorResponse } from "types";

export const getWaitlistEntries = async (
  _: unknown,
  filters: string,
  page: number,
  limit: number
): Promise<WaitlistResponse> => {
  const response: AxiosResponse<WaitlistResponse> = await axios.get(
    `/api/waitlists?page=${page}&limit=${limit}&filters=${filters || "{}"}`
  );

  return response.data;
};

/**
 * Fetches a list of waitlist entries for a specific activity group.
 */
export default function useWaitlistEntries(
  filters: string,
  page: number,
  limit: number,
  options?: QueryConfig<WaitlistResponse, AxiosError<ErrorResponse>>
): QueryResult<WaitlistResponse, AxiosError<ErrorResponse>> {
  return useQuery(
    ["waitlistEntries", filters, page, limit],
    getWaitlistEntries,
    options
  );
}
