import { omit } from "lodash";
import dataTypes from "@data-driven-forms/react-form-renderer/data-types";
import type { Schema } from "@data-driven-forms/react-form-renderer";
import componentTypes from "@data-driven-forms/react-form-renderer/component-types";
import { FormTemplateType } from "types/form";
import type {
  ActivityGroup,
  ActivityGroupFullListItem
} from "types/model/activity-group";
import type { Client } from "types/model/client";
import type {
  CancellationPolicy,
  CancellationPolicyFormData,
  CancellationPolicyRule} from "types/model/cancellation";
import {
  CancellationPolicyAssignmentType,
  RefundMethod
} from "types/model/cancellation";
import { DateDuration } from "types/date";
import type { Venue } from "types/model/venue";
import type { Field } from "types/model/field";
import type {
  PaymentMethodInstance} from "types/model/payment";
import {
  PaymentProviderName
} from "types/model/payment";
import { getIsClientPremiumPlanOrHigher } from "helpers/plan-level";
import { getRefundMethodsConfigHelpText } from "helpers/cancellations";
import { getCurrencyAmountForDisplay } from "helpers/currency";

interface GenerateCancellationPolicyFormSchemaData {
  cancellationPolicy?: CancellationPolicy<ActivityGroup[]>;
  activityGroups: ActivityGroupFullListItem[];
  venues: Venue[];
  activityFields: Field[];
  paymentMethods: PaymentMethodInstance[];
  client: Client;
  formTemplate?: FormTemplateType;
}

export const generateCancellationPolicyFormSchema = ({
  cancellationPolicy,
  activityGroups,
  venues,
  activityFields,
  paymentMethods,
  client,
  formTemplate = FormTemplateType.Default
}: GenerateCancellationPolicyFormSchemaData): Schema => {
  const getCancellationPolicyAssignmentTypeInitialValue = (
    cancellationPolicy: CancellationPolicy<ActivityGroup[]>
  ) => {
    if (cancellationPolicy?.appliesToAllActivityGroups) {
      return CancellationPolicyAssignmentType.AllActivityGroups;
    } else if (cancellationPolicy?.appliesToAllActivityGroups === false) {
      return CancellationPolicyAssignmentType.SelectedActivityGroups;
    }
    return null;
  };

  const hasStripePaymentsEnabled = paymentMethods.some(
    paymentMethod =>
      paymentMethod.provider.internalName === PaymentProviderName.Stripe &&
      paymentMethod.enabled
  );

  const fields = [
    {
      index: 0,
      arrayField: false,
      component: componentTypes.TEXT_FIELD,
      name: "name",
      label: "Name",
      isRequired: true,
      validate: [{ type: "required" }],
      formTemplate,
      initialValue: cancellationPolicy?.name
    },
    {
      index: 1,
      arrayField: false,
      component: "radio-group-with-description-plain",
      name: "assignmentType",
      label: "Assignment type",
      isRequired: true,
      validate: [{ type: "required" }],
      options: [
        {
          value: CancellationPolicyAssignmentType.AllActivityGroups,
          label: "All activities",
          description:
            "If selected, the policy will be applied to all activities."
        },
        {
          value: CancellationPolicyAssignmentType.SelectedActivityGroups,
          label: "Selected activities",
          description:
            "If selected, the policy will be applied to selected activities."
        }
      ],
      formTemplate,
      initialValue:
        getCancellationPolicyAssignmentTypeInitialValue(cancellationPolicy)
    },
    {
      index: 2,
      arrayField: false,
      component: "activity-groups",
      name: "activityGroups",
      label: "Activities to apply to",
      id: cancellationPolicy?._id,
      documentsKey: "cancellationPolicies",
      activityGroups,
      venues,
      activityFields,
      client,
      formTemplate,
      helpText:
        "Cancellation policies can also be assigned to activities when they are created or edited.",
      initialValue: cancellationPolicy?.activityGroups || [],
      condition: {
        when: "assignmentType",
        is: CancellationPolicyAssignmentType.SelectedActivityGroups,
        then: { visible: true }
      }
    },
    {
      index: 3,
      arrayField: false,
      component: componentTypes.SWITCH,
      name: "enabled",
      label: "Enabled",
      dataType: dataTypes.BOOLEAN,
      formTemplate,
      initialValue: cancellationPolicy ? cancellationPolicy.enabled : true
    },
    {
      index: 4,
      component: "section-header",
      name: "rulesHeader",
      title: "Rules",
      description:
        "Create distinct rules for multiple cancellation windows, each with its own configuration."
    },
    {
      index: 5,
      component: "cancellation-policy-rules",
      name: "rules",
      formTemplate,
      label: "Items",
      initialValue: cancellationPolicy?.rules,
      isNewCancellationPolicy: !cancellationPolicy,
      hasStripePaymentsEnabled,
      isRequired: true,
      validate: [
        { type: "required", message: "At least one rule is required." }
      ]
    }
  ];

  const schema = {
    fields
  };

  return schema;
};

interface GetCancellationPolicyDataForSubmissionData {
  formData: CancellationPolicyFormData;
}

export const getCancellationPolicyFormDataForSubmission = ({
  formData
}: GetCancellationPolicyDataForSubmissionData): CancellationPolicyFormData<
  string[]
> => {
  const formDataToSubmit: CancellationPolicyFormData<string[]> = omit(
    formData,
    ["activityGroups"]
  );
  if (
    formDataToSubmit.assignmentType ===
    CancellationPolicyAssignmentType.SelectedActivityGroups
  ) {
    const activityGroups = formData.activityGroups || [];
    formDataToSubmit.activityGroups = activityGroups.map(
      activityGroup => activityGroup._id
    );
  }

  return formDataToSubmit;
};

interface GenerateCancellationPolicyRuleFormSchemaData {
  formTemplate?: FormTemplateType;
  prefix?: string;
  editingItem?: CancellationPolicyRule;
  hasStripePaymentsEnabled: boolean;
  client: Client;
}

export const generateCancellationPolicyRuleFormSchema = ({
  formTemplate = FormTemplateType.Seamless,
  prefix = "",
  editingItem,
  hasStripePaymentsEnabled,
  client
}: GenerateCancellationPolicyRuleFormSchemaData) => {
  const prefixPath = prefix ? `${prefix}.` : "";

  const isClientPremiumPlanOrHigher = getIsClientPremiumPlanOrHigher(client);

  const refundMethodsOptionsInitialValue =
    editingItem?.config.refundMethods.filter(refundMethod => {
      if (refundMethod === RefundMethod.Stripe) {
        return hasStripePaymentsEnabled;
      } else if (refundMethod === RefundMethod.AccountCredit) {
        return isClientPremiumPlanOrHigher;
      }
      return false;
    });

  const fields = [
    {
      index: 0,
      arrayField: false,
      component: "date-validity",
      inputType: "number",
      name: `${prefixPath}cancellationWindow`,
      helpText:
        "The minimum amount of time before the activity session where the rule will apply.",
      label: "Cancellation window",
      unitOptions: [
        DateDuration.Hours,
        DateDuration.Days,
        DateDuration.Weeks,
        DateDuration.Months
      ],
      isRequired: true,
      min: 1,
      validate: [
        value => {
          if (!value?.amount) {
            return "Required";
          } else {
            const valueParsed = parseInt(value?.amount, 10);
            if (valueParsed < 1) {
              return "Amount cannot be less than 1.";
            }
          }
          return undefined;
        }
      ],
      formTemplate,
      initialValue: editingItem?.cancellationWindow
    },
    {
      index: 1,
      arrayField: false,
      component: componentTypes.TEXT_FIELD,
      inputType: "number",
      name: `${prefixPath}percentageToRefund`,
      label: "Percentage to refund",
      suffix: "%",
      isRequired: true,
      min: 0,
      max: 100,
      step: "any",
      validate: [
        // { type: 'required' }, TODO: probably want this but maybe need to allow 0 as well
        value => {
          const valueParsed = parseInt(value, 10);
          if (valueParsed < 0) {
            return "Percentage cannot be less than 0.";
          } else if (valueParsed > 100) {
            return "Percentage cannot be greater than 100.";
          }
          return undefined;
        }
      ],
      formTemplate,
      initialValue: editingItem?.config.percentageToRefund ?? 100
    },
    {
      index: 2,
      arrayField: false,
      component: "currency",
      name: `${prefixPath}fee`,
      label: "Fee to charge",
      min: 0,
      step: "any",
      validate: [
        value => {
          const valueParsed = parseInt(value, 10);
          if (valueParsed < 0) {
            return "Amount cannot be less than 0.";
          }
          return undefined;
        }
      ],
      helpText: "Fee will be applied per ticket item cancelled.",
      formTemplate,
      client,
      initialValue: editingItem?.config.fee
        ? getCurrencyAmountForDisplay(editingItem.config.fee, client.currency)
        : null,
      condition: {
        when: `${prefixPath}percentageToRefund`,
        is: (value: string) => {
          const valueParsed = parseInt(value, 10);
          return valueParsed > 0;
        }
      }
    },
    {
      index: 3,
      arrayField: false,
      component: "checkbox-multiple",
      name: `${prefixPath}refundMethods`,
      label: "Refunds methods",
      helpText: getRefundMethodsConfigHelpText(
        hasStripePaymentsEnabled,
        isClientPremiumPlanOrHigher
      ),
      formTemplate,
      options: [
        {
          label: "Stripe",
          value: RefundMethod.Stripe,
          disabled: !hasStripePaymentsEnabled
        },
        {
          label: "Account credit",
          value: RefundMethod.AccountCredit,
          disabled: !isClientPremiumPlanOrHigher
        }
      ],
      initialValue: refundMethodsOptionsInitialValue,
      condition: {
        when: `${prefixPath}percentageToRefund`,
        is: (value: string) => {
          const valueParsed = parseInt(value, 10);
          return valueParsed > 0;
        }
      }
    },
    {
      index: 4,
      arrayField: false,
      component: "date-validity",
      inputType: "number",
      name: `${prefixPath}accountCreditExpiry`,
      helpText:
        "Period for which account credit will be valid after it's issued to a used. Leave blank if you don't wish to set an expiry.",
      label: "Account credit expiry",
      formTemplate,
      initialValue: editingItem?.config.accountCreditExpiry,
      condition: [
        {
          when: `${prefixPath}percentageToRefund`,
          is: (value: string) => {
            const valueParsed = parseInt(value, 10);
            return valueParsed > 0;
          }
        },
        {
          when: `${prefixPath}refundMethods`,
          is: (value: string[]) => value?.includes(RefundMethod.AccountCredit)
        }
      ],
      validate: [
        value => {
          if (value?.amount) {
            const valueParsed = parseInt(value?.amount, 10);
            if (valueParsed < 1) {
              return "Amount cannot be less than 1, however it can be left blank.";
            }
          }
          return undefined;
        }
      ]
    }
  ];

  const schema = {
    fields
  };

  return schema;
};
