import { cn } from "utils/cn";
import { XCircleIcon } from "@heroicons/react/20/solid";

interface FormErrorProps {
  children: React.ReactNode;
  className?: string;
}

const FormError = ({
  children,
  className
}: FormErrorProps): React.ReactElement => (
  <div
    className={cn("mb-4 rounded-md bg-red-50 p-4", className)}
    data-cy="form-error"
  >
    <div className="flex">
      <div className="flex-shrink-0">
        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
      </div>
      <div className="ml-3 flex-1 md:flex md:justify-between">
        <div className="text-sm text-red-700">{children}</div>
      </div>
    </div>
  </div>
);

export default FormError;
