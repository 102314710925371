import axios from "axios";
import produce from "immer";
import type { MutationResultPair} from "react-query";
import { queryCache, useMutation } from "react-query";
import type { ErrorResponse } from "types";
import type { FormDataItem } from "types/form";
import type { Attendee } from "types/model/attendee";
import type { CurrentUser } from "types/model/user";

interface SaveAttendeeResponse {
  data: Attendee;
}

interface SaveAttendeeData {
  id: string;
  formData: FormDataItem[];
}

export default function useUserSaveAttendee(): MutationResultPair<
  SaveAttendeeResponse,
  ErrorResponse,
  SaveAttendeeData,
  unknown
> {
  return useMutation(
    data => {
      return axios.put(`/api/user/attendees/${data.id}`, data.formData);
    },
    {
      onSuccess: response => {
        const updatedAttendee = response.data;

        const currentUserData: CurrentUser =
          queryCache.getQueryData("currentUser");

        const currentUserDataUpdated = produce<CurrentUser>(
          currentUserData,
          (draft: CurrentUser) => {
            const attendeeToUpdateIndex = draft.attendees.findIndex(
              attendee => attendee._id === updatedAttendee._id
            );
            draft.attendees[attendeeToUpdateIndex] = updatedAttendee;
          }
        );

        queryCache.setQueryData("currentUser", currentUserDataUpdated);
        queryCache.invalidateQueries("currentUser");
        queryCache.invalidateQueries("basket");
      }
    }
  );
}
