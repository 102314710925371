import type {
  UseFieldApiConfig,
  UseFieldApiProps
} from "@data-driven-forms/react-form-renderer";
import { FormSpy, useFieldApi } from "@data-driven-forms/react-form-renderer";
import { Alert } from "components/Alert";
import { Autocomplete } from "components/form/field/Autocomplete";
import { handleStringifyFilters } from "features/bookings/utils/handleStringifyFilters";
import useWaitlistEntries from "features/waitlists/hooks/useWaitlistEntries";
import { getAttendeeFullName } from "helpers/helpers";
import useUserAttendees from "hooks/useUserAttendees";
import type { ActivityGroup } from "types/model/activity-group";
import { WaitlistType } from "types/model/activity-group";
import { WaitlistEntryType } from "types/model/waitlistEntry";
import { cn } from "utils/cn";

type Props = UseFieldApiProps<unknown, HTMLElement> & {
  activityGroup?: ActivityGroup;
};

/**
 * Used to select an attendee from a list of attendees.
 */
export const AttendeeOptions = ({
  user,
  attendee,
  activityGroup,
  ...props
}: Props) => {
  const { data: attendees, isFetching } = useUserAttendees(user);

  const options = (attendees?.length > 0 ? attendees : []).map(attendee => ({
    value: attendee._id,
    label: getAttendeeFullName(attendee),
    id: attendee._id,
    _id: attendee._id,
    enabled: true
  }));

  const noAttendees = !!user && !options.length && !isFetching;

  const { data: { data: userWaitlistEntries = [] } = {} } = useWaitlistEntries(
    handleStringifyFilters({
      user: user,
      activityGroup: activityGroup?._id
    }),
    0,
    1000,
    { enabled: !!user }
  );

  const joinedAllSessionsWaitlist =
    activityGroup?.waitlistType === WaitlistType.AllSessions &&
    !!userWaitlistEntries.find(
      entry =>
        entry.attendee?._id === attendee &&
        entry.type === WaitlistEntryType.AllSessions
    );

  return (
    <div>
      <div
        className={cn(
          (!user || !options.length) && "pointer-events-none opacity-50"
        )}
      >
        <Autocomplete
          {...props}
          name="attendee"
          id="attendee"
          label="Attendee"
          input={props.input}
          options={options}
          primaryField="label"
          shouldAddDisabledIndicator
          isRequired
          validate={props.validate}
          shouldRenderSuggestions={() => true}
        />
      </div>
      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
        <div />
        {joinedAllSessionsWaitlist && (
          <Alert className="mt-2 sm:col-span-2 sm:max-w-sm">
            This attendee has already joined the waitlist for this activity.
          </Alert>
        )}
        {noAttendees && (
          <Alert className="mt-2 sm:col-span-2 sm:max-w-sm">
            This user has no attendees.
          </Alert>
        )}
      </div>
    </div>
  );
};

/**
 * Used to select an attendee from a list of attendees.
 */
export const AdminSelectWaitlistAttendee = (props: UseFieldApiConfig) => {
  const fieldProps = useFieldApi(props);

  return (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => (
        <AttendeeOptions
          {...fieldProps}
          user={values.user}
          attendee={values.attendee}
        />
      )}
    </FormSpy>
  );
};
