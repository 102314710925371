import type { DateDuration } from "types/date";
import type { Booking } from "./booking";
import type { LineItem } from "./line-item";

export enum AccountCreditType {
  Credit = "credit",
  Debit = "debit"
}

export enum AccountCreditView {
  Active = "active",
  Inactive = "inactive"
}

export interface AccountCreditExpiresAfter {
  amount: number;
  unit?: DateDuration;
}

export interface AccountCreditAmount {
  accountCreditItem: AccountCreditItem<string, string, AccountCreditItem>;
  amount: number;
}

export interface AccountCreditItem<CI = string, LI = string, DI = string> {
  _id: any;
  type: AccountCreditType;
  amount: number;
  expires: Date;
  lineItem: LI;
  user: string;
  client: string;
  created: Date;
  updated: Date;
  creditItemUsedAgainst?: CI;
  debitItems?: DI[];
  save: () => Promise<void>;
  toObject: () => AccountCreditItem<CI, LI, DI>;
}

export interface AccountCreditItemWithUsedRemainingAmounts
  extends AccountCreditItem<
    string,
    string,
    AccountCreditItem<
      string,
      LineItem<string, string, string[], Booking<string, string>>
    >
  > {
  accountCreditItemStatus: AccountCreditItemStatus;
  amountUsed: number;
  amountRemaining: number;
}

export interface AccountCreditDeductCreditFormData {
  accountCreditItemId: string;
  userId: string;
  amount: string;
}

export interface AccountCreditDeductCreditRequestData {
  accountCreditItemId: string;
  userId: string;
  amount: number;
}

export enum AccountCreditItemStatus {
  Active = "active",
  FullyUsed = "fullyUsed",
  Expired = "expired"
}

export interface IssueAccountCreditForBookingFormData {
  bookingId: string;
  amount: number;
  dateValidity: AccountCreditDateValidity;
}

export interface ApplyAccountCreditForBookingFormData {
  bookingId: string;
  amount: number;
}

export interface AddAccountCreditItemFormData {
  userId: string;
  amount: string;
  specifyExpiry?: boolean;
  expiry?: string;
}

export interface AddAccountCreditItemRequestData {
  userId: string;
  amount: number;
  specifyExpiry?: boolean;
  expiry?: string;
}

export interface AccountCreditDateValidity {
  amount: string;
  unit: DateDuration;
}
