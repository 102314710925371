import axios from "axios";
import produce from "immer";
import type { MutationResultPair} from "react-query";
import { queryCache, useMutation } from "react-query";
import type { ErrorResponse } from "types";
import type { FormDataItem } from "types/form";
import type { Attendee } from "types/model/attendee";
import type { CurrentUser } from "types/model/user";

interface CreateAttendeeResponse {
  data: Attendee;
}

interface CreateAttendeeData {
  formData: FormDataItem[];
}

function useCreateCurrentUserAttendee(): MutationResultPair<
  CreateAttendeeResponse,
  ErrorResponse,
  CreateAttendeeData,
  unknown
> {
  return useMutation(
    data => {
      return axios.post("/api/user/attendees", data);
    },
    {
      onSuccess: response => {
        const newAttendee = response.data;

        const currentUserData: CurrentUser =
          queryCache.getQueryData("currentUser");

        const currentUserDataUpdated = produce<CurrentUser>(
          currentUserData,
          (draft: CurrentUser) => {
            draft.attendees.push(newAttendee);
          }
        );

        queryCache.setQueryData("currentUser", currentUserDataUpdated);
        queryCache.invalidateQueries("currentUser");
      }
    }
  );
}

export default useCreateCurrentUserAttendee;
