import { useState, useEffect } from "react";
import { cn } from "utils/cn";
import useUserAttendees from "hooks/useUserAttendees";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import useFormApi from "@data-driven-forms/react-form-renderer/use-form-api";
import IconExclamationCircleSolid from "public/images/icons/exclamation-circle_solid.svg";
import { FormTemplateType } from "types/form";
import { getAttendeeFullName } from "helpers/helpers";
import HelpText from "../HelpText";

const Select = (props): React.ReactElement => {
  const { getFieldState, change } = useFormApi();
  const {
    label,
    helpText,
    input,
    isRequired,
    index,
    arrayField,
    formTemplate = FormTemplateType.Default,
    inModal,
    meta: { error, touched }
  } = useFieldApi(props);

  const isDefaultFormTemplate = formTemplate === FormTemplateType.Default;
  const isSeamlessFormTemplate = formTemplate === FormTemplateType.Seamless;
  const isFirstItem = index === 0;

  const [userId, setUserId] = useState("");
  const userAttendeesInfo = useUserAttendees(userId);
  const userField = getFieldState("userId");

  useEffect(() => {
    if (userField.value) {
      setUserId(userField.value);
    }
  }, [userField]);

  const handleChange = (value, inputName: string) => {
    const activityGroupIdInputName = inputName.replace(
      ".attendeeId",
      ".activityGroupId"
    );
    const activityGroupTicketsInputName = inputName.replace(
      ".attendeeId",
      ".activityGroupTickets"
    );
    input.onChange(value);
    change(activityGroupIdInputName, "");
    change(activityGroupTicketsInputName, []);
  };

  return (
    <div
      className={cn(
        !inModal &&
          isDefaultFormTemplate &&
          "sm:grid sm:grid-cols-3 sm:items-start sm:gap-4",
        isDefaultFormTemplate &&
          !arrayField &&
          !isFirstItem &&
          "mt-5 border-t border-gray-200 pt-5",
        (isSeamlessFormTemplate || arrayField) && !isFirstItem && "mt-5"
      )}
    >
      <label
        className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
        htmlFor={input.name}
      >
        {label}
        {isRequired && "*"}
      </label>

      <div className="sm:col-span-2 sm:max-w-sm">
        <div className="relative">
          {userAttendeesInfo.isLoading || !userAttendeesInfo.data ? (
            <div className="mt-1 animate-pulse">
              <div className="h-9 rounded bg-gray-300"></div>
            </div>
          ) : (
            <select
              id={input.name}
              name={input.name}
              className={cn(
                "mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm",
                touched &&
                  error &&
                  "focus:shadow-outline-red border-red-300 text-red-900 placeholder-red-300 focus:border-red-300"
              )}
              value={input.value}
              onChange={value => handleChange(value, input.name)}
            >
              <option value="">Select attendee</option>
              {userAttendeesInfo.data.length &&
                userAttendeesInfo.data.map(attendee => {
                  return (
                    <option key={attendee._id} value={attendee._id}>
                      {getAttendeeFullName(attendee)}
                    </option>
                  );
                })}
              <option value="new-attendee">New attendee</option>
            </select>
          )}
          {touched && error && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <IconExclamationCircleSolid
                width={20}
                height={20}
                className="h-5 w-5 bg-white text-red-500"
              />
            </div>
          )}
        </div>

        {helpText && <HelpText label={label} helpText={helpText} />}
        {touched && error && (
          <p className="mt-2 text-sm text-red-600">{error}</p>
        )}
      </div>
    </div>
  );
};

export default Select;
