import useFormApi from "@data-driven-forms/react-form-renderer/use-form-api";
import { Dialog, Transition } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/24/outline";
import SubmitButton from "components/form/SubmitButton";
import { generateActivitySessionFormSchema } from "helpers/form/activity-group";
import { wait } from "helpers/helpers";
import { Fragment, useRef } from "react";
import { FormTemplateType } from "types/form";
import type { RepeatEndOption, RepeatOption } from "types/model/activity-group";
import type { Client } from "types/model/client";
export interface ActivitySessionFormData {
  date: string;
  endTime: string;
  startTime: string;
  placeLimit?: string;
  repeatOption?: RepeatOption;
  repeatDays?: string[];
  repeatEndOption?: RepeatEndOption;
  repeatOccurrences?: string;
  repeatEndDate?: string;
}

interface ActivitySessionsAddEditModalProps {
  isOpen: boolean;
  editingSessionId?: string;
  client: Client;
  onCancel: () => void;
  onConfirmSessionAddEdit: (
    formData: ActivitySessionFormData,
    sessionId?: string
  ) => void;
  setIsOpen: (isOpen: boolean) => void;
}

const subFormPrefix = "activitySessionSubForm";

const ActivitySessionsAddEditModal = ({
  isOpen,
  editingSessionId,
  client,
  onConfirmSessionAddEdit,
  onCancel,
  setIsOpen
}: ActivitySessionsAddEditModalProps): React.ReactElement => {
  const wrapperRef = useRef();

  const { renderForm, getState, change, blur } = useFormApi();

  const formValues = getState().values;

  const formSchema = generateActivitySessionFormSchema({
    formTemplate: FormTemplateType.Seamless,
    prefix: subFormPrefix,
    editingSession: formValues.datesInstances?.find(
      item => item._id === editingSessionId
    ),
    client
  });

  const handleSubmit = async () => {
    const { valid: isValid, values, errors } = getState();
    const formData = values[subFormPrefix];
    const formErrors = errors[subFormPrefix];

    if (!isValid && formErrors) {
      for (const key in formErrors) {
        blur(`${subFormPrefix}.${key}`);
      }
      return;
    }

    setIsOpen(false);
    await wait(300); // Wait for modal to transition to not open before updating state stuff.
    onConfirmSessionAddEdit(formData, editingSessionId);
    change(subFormPrefix, undefined);
  };

  const handleClose = async () => {
    setIsOpen(false);
    await wait(300); // Wait for modal to transition to not open before updating state stuff.
    onCancel();
    change(subFormPrefix, undefined);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={wrapperRef}
        open={isOpen}
        onClose={handleClose}
        data-cy="modal-activity-sessions-add-edit"
      >
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block min-w-full transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:min-w-min sm:max-w-lg sm:align-middle">
              {/* Added `min-w-full sm:min-w-min` so it goes full width on mobile */}
              <div
                className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4"
                ref={wrapperRef}
              >
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                    <CalendarIcon
                      className="h-6 w-6 text-indigo-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 w-full sm:ml-4 sm:mt-0">
                    {/* Added w-full`to the above */}
                    <Dialog.Title
                      as="h3"
                      className="text-center text-lg font-medium leading-6 text-gray-900 sm:text-left"
                    >
                      {editingSessionId ? "Edit session" : "Add new session"}
                    </Dialog.Title>
                    <div className="mb-4 mt-6">
                      {renderForm(formSchema.fields)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <SubmitButton
                  buttonType="button"
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleSubmit}
                >
                  OK
                </SubmitButton>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ActivitySessionsAddEditModal;
