import { cn } from "utils/cn";
import { kebabCase } from "lodash";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import { FormTemplateType } from "types/form";
import HelpText from "../HelpText";
import Badge from "components/Badge";

const Checkbox = (props): React.ReactElement => {
  const {
    label,
    input,
    isRequired,
    isDisabled,
    isAdminOnly,
    meta: { error, touched },
    index,
    helpText,
    inModal,
    arrayField,
    formTemplate = FormTemplateType.Default
  } = useFieldApi(props);

  const isSeamlessFormTemplate = formTemplate === FormTemplateType.Seamless;
  const isFirstItem = index === 0;

  return (
    <>
      {isSeamlessFormTemplate ? (
        <div
          className={cn("relative flex items-start", !isFirstItem && "mt-7")}
          data-cy={`form-field-${kebabCase(label)}`}
        >
          <div className="flex h-5 items-center">
            <input
              id={input.name}
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              {...input}
            />
          </div>
          <div className="ml-3 text-sm">
            <label htmlFor={input.name} className="font-medium text-gray-700">
              {label}
              {isRequired && "*"}
            </label>
            {helpText && <HelpText label={label} helpText={helpText} />}
            {touched && error && (
              <p className="mt-2 text-sm text-red-600">{error}</p>
            )}
          </div>
        </div>
      ) : (
        <div
          className={cn(
            !inModal && "sm:grid sm:grid-cols-3 sm:items-start sm:gap-4",
            !arrayField && index !== 0 && "mt-5 border-t border-gray-200 pt-5",
            arrayField && index !== 0 && "mt-5"
          )}
          data-cy={`form-field-${kebabCase(label)}`}
        >
          <label
            className={cn(
              "hidden text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2",
              !isAdminOnly && "sm:block",
              isAdminOnly && "flex-wrap gap-2 sm:flex"
            )}
            htmlFor={input.name}
          >
            {label}
            {isRequired && "*"}
            {isAdminOnly && <Badge>Admin only</Badge>}
          </label>
          <div className="relative mt-1 flex items-start sm:col-span-2 sm:max-w-sm">
            <div className="absolute flex h-5 items-center">
              <input
                id={input.name}
                type="checkbox"
                className={cn(
                  "form-checkbox h-4 w-4 rounded text-indigo-600 focus:ring-indigo-500",
                  isDisabled && "opacity-50"
                )}
                disabled={isDisabled}
                data-cy={`input-${kebabCase(label)}`}
                {...input}
              />
            </div>
            <div className="pl-7 text-sm">
              <label htmlFor={input.name} className="font-medium text-gray-700">
                {label}
                {isAdminOnly && (
                  <Badge className="ml-2 sm:hidden">Admin only</Badge>
                )}
              </label>
              {helpText && <HelpText label={label} helpText={helpText} />}
              {touched && error && (
                <p className="mt-2 text-sm text-red-600">{error}</p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Checkbox;
