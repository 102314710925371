import { useState, useEffect } from "react";
import { cn } from "utils/cn";
import { kebabCase } from "lodash";
import type {
  UseFieldApiConfig,
  UseFieldApiProps
} from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import DiscountRuleActivityGroupsSelectModal from "components/admin/DiscountRuleActivityGroupsSelectModal";
import { FormTemplateType } from "types/form";
import HelpText from "../HelpText";
import type { ActivityGroup } from "types/model/activity-group";
import { getActivityTitle, getActivityVenueName } from "helpers/helpers";
import {
  getActivityGroupStartEnd,
  sortActivityGroupsByStartDate
} from "helpers/activity";
import type { Client } from "types/model/client";
import type { DiscountRuleType } from "types/model/discount-rule";
import type { Field } from "types/model/field";
import type { Venue } from "types/model/venue";

interface DiscountRuleActivityGroupsProps
  extends UseFieldApiProps<ActivityGroup[], HTMLElement> {
  id?: string;
  discountRuleType?: DiscountRuleType;
  venues?: Venue[];
  activityGroups?: ActivityGroup[];
  activityFields?: Field[];
  client?: Client;
}

const DiscountRuleActivityGroups = (
  props: UseFieldApiConfig
): React.ReactElement => {
  const {
    label,
    input,
    isRequired,
    meta: { error, touched },
    index,
    helpText,
    arrayField,
    formTemplate = FormTemplateType.Default,
    id,
    discountRuleType,
    venues,
    activityGroups,
    activityFields,
    client
  }: DiscountRuleActivityGroupsProps = useFieldApi(props);

  const isDefaultFormTemplate = formTemplate === FormTemplateType.Default;
  const isSeamlessFormTemplate = formTemplate === FormTemplateType.Seamless;
  const isFirstItem = index === 0;
  const [isActivitiesSelectModalOpen, setIsActivitiesSelectModalOpen] =
    useState(false);
  const [selectedActivityGroupIds, setSelectedActivityGroupIds] = useState<
    string[]
  >([]);

  const handleActivityGroupRemove = (activityGroupIdToRemove: string) => {
    const updateActivityGroupIds = input.value.filter(
      activityGroup => activityGroup._id !== activityGroupIdToRemove
    );
    input.onChange(updateActivityGroupIds);
  };

  const handleUpdateActivityGroups = (selectedActivityGroupIds: string[]) => {
    const currentValue = input.value || [];
    const currentActivityGroupIds = activityGroups.map(
      activityGroup => activityGroup._id
    );
    const pastActivityGroups = currentValue.filter(
      activityGroup => !currentActivityGroupIds.includes(activityGroup._id)
    );
    const selectedActivityGroups = activityGroups.filter(activityGroup =>
      selectedActivityGroupIds.includes(activityGroup._id)
    );
    input.onChange([...pastActivityGroups, ...selectedActivityGroups]);
  };

  useEffect(() => {
    if (input.value) {
      setSelectedActivityGroupIds(
        input.value.map(activityGroup => activityGroup._id)
      );
    } else {
      setSelectedActivityGroupIds([]);
    }
  }, [input.value]);

  const resetSelectedActivityGroupIds = () => {
    const currentValue = input.value || [];
    setSelectedActivityGroupIds(
      currentValue.map(activityGroup => activityGroup._id)
    );
  };

  return (
    <div
      className={cn(
        isDefaultFormTemplate &&
          "sm:grid sm:grid-cols-3 sm:items-start sm:gap-4",
        isDefaultFormTemplate &&
          !arrayField &&
          !isFirstItem &&
          "mt-5 border-t border-gray-200 pt-5",
        (isSeamlessFormTemplate || arrayField) && !isFirstItem && "mt-5"
      )}
      data-cy={`form-field-${kebabCase(label)}`}
    >
      <label
        className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
        htmlFor={input.name}
      >
        {label}
        {isRequired && "*"}
      </label>
      <div className="relative mt-1 sm:max-w-sm">
        <fieldset>
          <legend className="sr-only">{label}</legend>
          <div>
            {input.value.length > 0 && (
              <ul
                role="list"
                className="mb-3 divide-y divide-gray-200 border-b border-gray-200"
              >
                {input.value
                  .sort(sortActivityGroupsByStartDate)
                  .map((activityGroup, index) => (
                    <li
                      key={activityGroup._id}
                      className={cn(
                        "flex items-center justify-between pb-3",
                        index !== 0 && "pt-3"
                      )}
                    >
                      <div>
                        <div className="text-sm font-medium text-gray-700">
                          {getActivityTitle(activityGroup)}
                        </div>
                        <div className="text-sm text-gray-500">
                          {getActivityVenueName(activityGroup)}
                        </div>
                        <div className="text-sm text-gray-500">
                          {getActivityGroupStartEnd(activityGroup, client)}
                        </div>
                      </div>

                      <button
                        type="button"
                        className="ml-6 rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() =>
                          handleActivityGroupRemove(activityGroup._id)
                        }
                      >
                        Remove
                        <span className="sr-only">
                          {" "}
                          {getActivityTitle(activityGroup)}
                        </span>
                      </button>
                    </li>
                  ))}
              </ul>
            )}
            <button
              type="button"
              className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={() => setIsActivitiesSelectModalOpen(true)}
            >
              <PlusCircleIcon
                className="-ml-0.5 mr-2 h-4 w-4"
                aria-hidden="true"
              />
              Select activites
            </button>
          </div>

          <DiscountRuleActivityGroupsSelectModal
            id={id}
            venues={venues}
            fields={activityFields}
            type={discountRuleType}
            selectedActivityGroupIds={selectedActivityGroupIds}
            isOpen={isActivitiesSelectModalOpen}
            setSelectedActivityGroupIds={setSelectedActivityGroupIds}
            resetSelectedActivityGroupIds={resetSelectedActivityGroupIds}
            handleUpdateActivityGroups={handleUpdateActivityGroups}
            setIsOpen={setIsActivitiesSelectModalOpen}
          />

          {helpText && <HelpText label={label} helpText={helpText} />}
          {touched && error && (
            <p className="mt-5 text-sm text-red-600">{error}</p>
          )}
        </fieldset>
      </div>
    </div>
  );
};

export default DiscountRuleActivityGroups;
