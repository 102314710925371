import type {
  UseFieldApiConfig,
  UseFieldApiProps
} from "@data-driven-forms/react-form-renderer";
import { FormSpy, useFieldApi } from "@data-driven-forms/react-form-renderer";
import { Alert } from "components/Alert";
import { handleStringifyFilters } from "features/bookings/utils/handleStringifyFilters";
import useWaitlistEntries from "features/waitlists/hooks/useWaitlistEntries";
import { renderActivityDateString } from "helpers/helpers";
import type { Activity, Ticket } from "types/model/activity";
import type { ActivityGroup } from "types/model/activity-group";
import { WaitlistType } from "types/model/activity-group";
import type { Client } from "types/model/client";
import type { SubscriptionPlan } from "types/model/subscription-plan";
import type { WaitlistEntry } from "types/model/waitlistEntry";
import { cn } from "utils/cn";

type Props = UseFieldApiProps<unknown, HTMLElement> & {
  sessions?: {
    ticket: Ticket<SubscriptionPlan>;
    activities: Activity<string>[];
  }[];
  client?: Client;
  activityGroup?: ActivityGroup;
  userWaitlistEntries?: WaitlistEntry[];
  currentAttendee: string;
  user: string;
};

/**
 * Used to select sessions from a list of sessions. Used in the admin waitlist
 * entry creation form.
 */
const SessionOptions = (props: Props) => {
  const {
    label,
    required,
    meta: { error, touched },
    input,
    sessions = [],
    client,
    activityGroup,
    currentAttendee,
    user
  } = props;

  const data = sessions?.[0];

  const { data: { data: userWaitlistEntries = [] } = {} } = useWaitlistEntries(
    handleStringifyFilters({
      user: user,
      activityGroup: activityGroup?._id
    }),
    0,
    1000,
    { enabled: !!user }
  );

  const options = data?.activities?.map(option => {
    const userEntry = userWaitlistEntries.find(
      entry =>
        entry?.activity?._id === option._id &&
        entry.attendee?._id === currentAttendee
    );
    const joinedWaitlist =
      activityGroup.waitlistType === WaitlistType.SingleSession && !!userEntry;

    return {
      value: option._id,
      label: renderActivityDateString({
        activityDate: option.date,
        dateOnly: false,
        timeOnly: false,
        timeZone: client.timeZone,
        includeYear: false,
        timeOverride: data.ticket.timeDisplay
      }),
      disabled: joinedWaitlist
    };
  });

  const waitlistType = activityGroup.waitlistType;

  const joinedAllSingleSessionsWaitlist =
    waitlistType === WaitlistType.SingleSession &&
    userWaitlistEntries.length > 0 &&
    userWaitlistEntries.filter(entry => entry.attendee?._id === currentAttendee)
      .length === options.length;

  const handleChange = (value: string) => {
    const currentValue = Array.isArray(input.value) ? input.value : [];
    const newValue = currentValue.includes(value)
      ? currentValue.filter(v => v !== value)
      : [...currentValue, value];
    input.onChange(newValue);
  };

  const hasError = touched && error;

  const joinedAllSessionsWaitlist =
    activityGroup?.waitlistType === WaitlistType.AllSessions &&
    !!userWaitlistEntries.find(entry => entry.attendee?._id === input.value);

  return (
    <div className="mt-5 border-t border-gray-200 pt-5 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
      <p
        className={cn(
          "block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2",
          (!currentAttendee || joinedAllSingleSessionsWaitlist) && "opacity-50"
        )}
      >
        {label}
        {required && "*"}
      </p>
      <div className="mt-1 sm:col-span-2 sm:max-w-sm">
        {joinedAllSessionsWaitlist ? (
          <Alert className="mt-1">
            This attendee has already joined the waitlist for this activity.
          </Alert>
        ) : (
          options?.map(option => {
            const alreadyJoined = !!userWaitlistEntries.find(
              entry =>
                entry.activity?._id === option.value &&
                entry.attendee?._id === currentAttendee
            );

            const checked =
              (Array.isArray(input.value) &&
                input.value.includes(option.value)) ||
              alreadyJoined;

            return (
              <div key={option.value} className="flex items-center">
                <input
                  type="checkbox"
                  id={option.value}
                  value={option.value}
                  name={input.name}
                  disabled={option.disabled || !currentAttendee}
                  checked={checked}
                  onChange={() => handleChange(option.value)}
                  className={cn(
                    "h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500",
                    alreadyJoined && "opacity-50"
                  )}
                />
                <label
                  htmlFor={option.value}
                  className={cn(
                    "ml-2 block text-sm text-gray-900",
                    (option.disabled || !currentAttendee) && "opacity-50"
                  )}
                >
                  {option.label}
                  {option.disabled && (
                    <span className="text-xs text-gray-500">
                      {" "}
                      (Joined waitlist)
                    </span>
                  )}
                </label>
              </div>
            );
          })
        )}
        {hasError && <div className="mt-1 text-sm text-red-500">{error}</div>}
        {joinedAllSingleSessionsWaitlist && (
          <Alert className="mt-1">
            The attendee has already joined every waitlist for this activity.
          </Alert>
        )}
      </div>
    </div>
  );
};

/**
 * Used to select sessions from a list of sessions. Used in the admin waitlist
 * entry creation form.
 */
export const AdminSelectWaitlistSessions = (props: UseFieldApiConfig) => {
  const fieldProps = useFieldApi(props);

  return (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => (
        <SessionOptions
          {...fieldProps}
          currentAttendee={values.attendee}
          user={values.user}
        />
      )}
    </FormSpy>
  );
};
