import type { AxiosError, AxiosResponse } from "axios";
import axios from "axios";
import type { MutationResultPair } from "react-query";
import { queryCache, useMutation } from "react-query";
import type { ErrorResponse } from "types";
import type { FormDataItem } from "types/form";
import type { Venue } from "types/model/venue";

export interface CreateVenueData {
  formData: FormDataItem[];
}

export default function useCreateVenue(): MutationResultPair<
  AxiosResponse<Venue>,
  AxiosError<ErrorResponse>,
  CreateVenueData,
  unknown
> {
  return useMutation(
    data => {
      return axios.post("/api/venues", data).catch(err => {
        throw new Error(err.response.data.message);
      });
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries("venuesFullList");
        queryCache.invalidateQueries("venues");
        queryCache.invalidateQueries("client"); // For onboarding status
      }
    }
  );
}
