import { cn } from "utils/cn";
import type { ButtonHTMLAttributes } from "react";

interface ButtonProps {
  disabled?: boolean;
  isLoading?: boolean;
  children: React.ReactNode;
  variant?: string;
  type?: string;
  buttonType?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  className?: string;
  customClassName?: string; // TODO: Eventually replace className with this
  form?: string;
  onClick?: (data: unknown) => void;
  cy?: string;
}

const SubmitButton = ({
  disabled,
  isLoading,
  children,
  type = "primary",
  variant = "default",
  buttonType = "submit",
  className,
  customClassName,
  cy,
  form,
  onClick
}: ButtonProps): React.ReactElement => {
  const isDefaultVarient = variant === "default";
  const isFullWidthVarient = variant === "fullWidth";
  const isTypePrimary = type === "primary";
  const isTypeWhite = type === "white";

  return (
    <button
      type={buttonType}
      {...(form ? { form } : {})}
      disabled={disabled}
      className={cn(
        className,
        customClassName,
        !className &&
          "justify-center rounded-md border px-4 py-2 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",
        !className &&
          isTypePrimary &&
          "border-transparent bg-indigo-600 text-white hover:bg-indigo-700",
        !className &&
          isTypeWhite &&
          "border-gray-300 bg-white text-gray-700 hover:bg-gray-50",
        !className && isDefaultVarient && "inline-flex leading-5",
        !className && isFullWidthVarient && "flex w-full shadow-sm"
      )}
      {...(onClick ? { onClick } : {})}
      data-cy={cy || "btn-submit"}
    >
      <span className={cn(isLoading && "invisible")}>{children}</span>
      {isLoading && (
        <svg
          width="38"
          height="38"
          viewBox="0 0 38 38"
          xmlns="http://www.w3.org/2000/svg"
          stroke={isTypeWhite ? "#4f46e5" : "#ffffff"}
          style={{ width: 19, height: 19 }}
          className="absolute"
          //  mx-auto left-0 right-0
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(1 1)" strokeWidth="2">
              <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="1s"
                  repeatCount="indefinite"
                />
              </path>
            </g>
          </g>
        </svg>
      )}
    </button>
  );
};

export default SubmitButton;
