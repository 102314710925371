import { useState } from "react";
import type {
  UseFieldApiProps
} from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import { useFormApi } from "@data-driven-forms/react-form-renderer";
import useActivityGroup from "hooks/useActivityGroup";
import useActivityGroupSales from "hooks/useActivityGroupSales";
import useClient from "hooks/useClient";
import type { Ticket} from "types/model/activity";
import { TicketType } from "types/model/activity";
import {
  formatCurrency,
  getActivityTitle,
  getActivityVenueName,
  renderActivityDateString,
  renderTicketTypeFriendlyName
} from "helpers/helpers";
import {
  getHaveAnyRemainingSessionsSoldOut,
  getIsActivitySessionAvailableToPurchase,
  getIsActivitySessionEnabledButPast,
  getIsSessionAvailableForSingleSessionTicket
} from "helpers/activity";
import CreateBookingItemAlert from "components/admin/CreateBookingItemWarning";
import type { Hash } from "types/general";
import type { ActivityGroup } from "types/model/activity-group";
import type { Client } from "types/model/client";
import { getCanAddOnBeUsedForActivity } from "helpers/ticket";
import type { CreateAdminBookingTicketSelectItem } from "types/model/admin-booking";

const getDoesActivityGroupHavePastSessions = (
  activityGroup: ActivityGroup,
  client: Client
) => {
  const doesActivityGroupHavePastSessions = activityGroup.activities.some(
    activity => {
      return getIsActivitySessionEnabledButPast(
        activity,
        activityGroup,
        client
      );
    }
  );

  return doesActivityGroupHavePastSessions;
};

interface AdminBookingActivityTicketSelectProps
  extends UseFieldApiProps<CreateAdminBookingTicketSelectItem[], HTMLElement> {
  activityGroupId?: string;
}

const subFormPrefix = "addBookingItemSubForm";

const AdminBookingActivityTicketSelect = (props): React.ReactElement => {
  const {
    input,
    meta: { error, touched },
    activityGroupId
  }: AdminBookingActivityTicketSelectProps = useFieldApi(props);
  const { change } = useFormApi();
  const activityGroupQueryInfo = useActivityGroup(activityGroupId);
  const activityGroupSalesQueryInfo = useActivityGroupSales(activityGroupId);
  const clientQueryInfo = useClient();

  const [showPastSessionsHash, setShowPastSessionsHash] = useState<Hash>({});

  const handleTicketSelect = (
    e: React.ChangeEvent<HTMLInputElement>,
    selectedTicket: Ticket,
    activityId?: string
  ): void => {
    const existingTicketIndex = input.value
      ? input.value.findIndex(
          ticketItem =>
            ticketItem.ticket._id === selectedTicket._id &&
            (ticketItem.ticket.type === TicketType.All ||
              (ticketItem.ticket.type === TicketType.Single &&
                ticketItem.activityId === activityId))
        )
      : -1;

    if (existingTicketIndex > -1) {
      const ticketsWithRemoved = input.value.filter(
        (ticket, index) => index !== existingTicketIndex
      );
      input.onChange(ticketsWithRemoved);
    } else {
      // Set the full activity group data in the form state in case there are any past sessions
      // selected (which aren't included in activity group full list data)
      change(`${subFormPrefix}.activityGroup`, activityGroupQueryInfo.data);
      input.onChange([
        ...input.value,
        {
          ticket: selectedTicket,
          ...(selectedTicket.type === TicketType.Single ? { activityId } : {})
        }
      ]);
    }
  };

  const handleAddonSelect = (
    ticketId: string,
    activityId: string,
    addOnId: string,
    checked: boolean
  ): void => {
    const addOn = activityGroupQueryInfo.data.addOns.find(
      item => item._id === addOnId
    );
    const activity = activityGroupQueryInfo.data.activities.find(
      item => item._id === activityId
    );

    const parentTicketIndex = input.value
      ? input.value.findIndex(
          item =>
            item.ticket._id === ticketId &&
            (item.ticket.type === TicketType.All ||
              (item.ticket.type === TicketType.Single &&
                item.activityId === activityId))
        )
      : -1;

    if (parentTicketIndex > -1) {
      const newData = [...input.value];
      const parentTicket = newData[parentTicketIndex].ticket;
      let addOnItems = newData[parentTicketIndex].addOnItems || [];
      if (checked) {
        addOnItems.push({
          ...addOn,
          ...(parentTicket.type !== TicketType.Single && {
            activity
          })
        });
      } else {
        addOnItems = addOnItems.filter(
          addOnItem =>
            !(
              addOnItem._id === addOnId &&
              (parentTicket.type === TicketType.Single ||
                (parentTicket.type === TicketType.All &&
                  addOnItem.activity._id === activityId))
            )
        );
      }
      newData[parentTicketIndex].addOnItems = addOnItems;

      input.onChange(newData);
    }
  };

  const getIsTicketOptionChecked = (
    ticketId: string,
    activityId?: string
  ): boolean => {
    const isTicketOptionChecked =
      input.value &&
      input.value.find(
        item =>
          item.ticket._id === ticketId &&
          (item.ticket.type === TicketType.All ||
            (item.ticket.type === TicketType.Single &&
              item.activityId === activityId))
      );

    return !!isTicketOptionChecked;
  };

  return (
    <div>
      {activityGroupQueryInfo.isLoading ||
      activityGroupSalesQueryInfo.isLoading ||
      clientQueryInfo.isLoading ? (
        <div className="my-4 max-w-lg">
          <div className="my-4 animate-pulse">
            <div className="mb-2 h-6 w-2/3 rounded bg-gray-300"></div>
            <div className="mb-1 h-4 w-1/2 rounded bg-gray-300"></div>
          </div>
        </div>
      ) : activityGroupQueryInfo.data &&
        activityGroupSalesQueryInfo.data &&
        clientQueryInfo.data ? (
        <>
          <div className="my-4">
            <div className="text-base font-medium text-gray-900">
              {getActivityTitle(activityGroupQueryInfo.data)}
            </div>
            <div className="text-sm text-gray-500">
              {getActivityVenueName(activityGroupQueryInfo.data)}
            </div>
          </div>
          <div className="max-w-lg space-y-6">
            {activityGroupQueryInfo.data.tickets.filter(ticket =>
              [TicketType.All, TicketType.Single].includes(ticket.type)
            ).length === 0 && (
              <div className="mt-2 text-sm text-gray-900">
                No tickets are available for this activity.
              </div>
            )}
            {activityGroupQueryInfo.data.tickets
              .filter(ticket =>
                [TicketType.All, TicketType.Single].includes(ticket.type)
              )
              .map(ticket => {
                if (ticket.type === TicketType.All) {
                  return (
                    <div key={ticket._id}>
                      <div className="mb-4 text-base font-medium text-gray-900">
                        {ticket.name}
                        {!ticket.enabled && " (disabled)"}
                      </div>
                      <div className="relative flex items-start">
                        <div className="flex h-5 items-center">
                          <input
                            id={`${input.name}_${ticket._id}`}
                            name={`${input.name}_${ticket._id}`}
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            onChange={(e): void =>
                              handleTicketSelect(e, ticket)
                            }
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor={`${input.name}_${ticket._id}`}
                            className="font-medium text-gray-700"
                          >
                            {renderTicketTypeFriendlyName(ticket.type)}
                            {ticket.haveSessionsPast &&
                              `, ${ticket.remainingSessionsCount} remaining`}
                            <span className="block text-gray-500">
                              {ticket.shouldProRata
                                ? formatCurrency({
                                    rawAmount: ticket.proRataPrice,
                                    currency: clientQueryInfo.data.currency
                                  })
                                : formatCurrency({
                                    rawAmount: ticket.price,
                                    currency: clientQueryInfo.data.currency
                                  })}
                            </span>
                            <>
                              {ticket.placeLimit &&
                                activityGroupSalesQueryInfo.data
                                  .allSessionTicketSales[ticket._id] >=
                                  ticket.placeLimit && (
                                  <CreateBookingItemAlert color="yellow">
                                    Ticket limit has been reached
                                  </CreateBookingItemAlert>
                                )}
                              {getHaveAnyRemainingSessionsSoldOut(
                                activityGroupQueryInfo.data,
                                activityGroupSalesQueryInfo.data,
                                clientQueryInfo.data
                              ) && (
                                <CreateBookingItemAlert color="yellow">
                                  Place limit has been reached
                                </CreateBookingItemAlert>
                              )}
                            </>
                          </label>
                          {getIsTicketOptionChecked(ticket._id) &&
                            Boolean(
                              activityGroupQueryInfo.data.addOns.filter(
                                addOn => addOn.enabled
                              ).length
                            ) && (
                              <div className="mt-2 space-y-4">
                                {activityGroupQueryInfo.data.activities
                                  .filter(activity =>
                                    getIsActivitySessionAvailableToPurchase(
                                      activity,
                                      activityGroupQueryInfo.data,
                                      clientQueryInfo.data
                                    )
                                  )
                                  .map(activity => (
                                    <fieldset key={activity._id}>
                                      <legend className="mb-1 text-sm text-gray-700">
                                        Add-ons for{" "}
                                        {renderActivityDateString({
                                          activityDate: activity.date,
                                          dateOnly: false,
                                          timeOnly: false,
                                          timeZone:
                                            clientQueryInfo.data.timeZone
                                        })}
                                      </legend>
                                      <div className="space-y-1">
                                        {activityGroupQueryInfo.data.addOns
                                          .filter(
                                            addOn =>
                                              addOn.enabled &&
                                              getCanAddOnBeUsedForActivity(
                                                addOn,
                                                activity._id
                                              )
                                          )
                                          .map(addOn => (
                                            <div
                                              key={`${ticket._id}_${activity._id}_${addOn._id}`}
                                              className="relative flex items-start justify-center sm:justify-start"
                                            >
                                              <div className="flex h-5 items-center">
                                                <input
                                                  id={`${ticket._id}_${activity._id}_${addOn._id}`}
                                                  name={`${ticket._id}_${activity._id}_${addOn._id}`}
                                                  type="checkbox"
                                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                  onChange={e =>
                                                    handleAddonSelect(
                                                      ticket._id,
                                                      activity._id,
                                                      addOn._id,
                                                      e.target.checked
                                                    )
                                                  }
                                                />
                                              </div>
                                              <div className="ml-3 text-sm">
                                                <label
                                                  htmlFor={`${ticket._id}_${activity._id}_${addOn._id}`}
                                                  className="text-gray-700"
                                                >
                                                  {addOn.name}
                                                </label>
                                                <span className="ml-2 text-gray-500">
                                                  +
                                                  {formatCurrency({
                                                    rawAmount: addOn.price,
                                                    currency:
                                                      clientQueryInfo.data
                                                        .currency
                                                  })}
                                                </span>
                                              </div>
                                            </div>
                                          ))}
                                      </div>
                                    </fieldset>
                                  ))}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  );
                } else if (ticket.type === TicketType.Single) {
                  return (
                    <div key={ticket._id}>
                      {getDoesActivityGroupHavePastSessions(
                        activityGroupQueryInfo.data,
                        clientQueryInfo.data
                      ) ? (
                        <div className="mb-4 text-base font-medium text-gray-900">
                          {ticket.name}
                          {!ticket.enabled && " (disabled)"}
                          <a
                            className="ml-2 cursor-pointer text-sm font-medium text-indigo-600 hover:text-indigo-900 focus:outline-none"
                            onClick={() =>
                              setShowPastSessionsHash({
                                ...showPastSessionsHash,
                                [ticket._id]: !showPastSessionsHash[ticket._id]
                              })
                            }
                          >
                            [
                            {showPastSessionsHash[ticket._id] ? "Hide" : "Show"}{" "}
                            past sessions]
                          </a>
                        </div>
                      ) : (
                        <div className="mb-4 text-base font-medium text-gray-900">
                          {ticket.name}
                          {!ticket.enabled && " (disabled)"}
                        </div>
                      )}
                      <ul className="space-y-4">
                        {activityGroupQueryInfo.data.activities
                          .filter(
                            activity =>
                              ((showPastSessionsHash[ticket._id] &&
                                activity.enabled) ||
                                getIsActivitySessionAvailableToPurchase(
                                  activity,
                                  activityGroupQueryInfo.data,
                                  clientQueryInfo.data
                                )) &&
                              getIsSessionAvailableForSingleSessionTicket(
                                activity,
                                ticket
                              )
                          )
                          .map(activity => (
                            <li key={`${activity._id}_${ticket._id}`}>
                              <div className="relative flex items-start">
                                <div className="flex h-5 items-center">
                                  <input
                                    id={`${input.name}_${activity._id}_${ticket._id}`}
                                    name={`${input.name}_${activity._id}_${ticket._id}`}
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    onChange={(e): void =>
                                      handleTicketSelect(
                                        e,
                                        ticket,
                                        activity._id
                                      )
                                    }
                                  />
                                </div>
                                <div className="ml-3 text-sm">
                                  <label
                                    htmlFor={`${input.name}_${activity._id}_${ticket._id}`}
                                    className="font-medium text-gray-700"
                                  >
                                    {renderActivityDateString({
                                      activityDate: activity.date,
                                      dateOnly: false,
                                      timeOnly: false,
                                      timeZone: clientQueryInfo.data.timeZone
                                    })}
                                    <span className="block text-gray-500">
                                      {formatCurrency({
                                        rawAmount: ticket.price,
                                        currency: clientQueryInfo.data.currency
                                      })}
                                    </span>
                                    <>
                                      {ticket.placeLimit &&
                                        activityGroupSalesQueryInfo.data
                                          .singleSessionTicketSales[
                                          ticket._id
                                        ]?.[activity._id] >=
                                          ticket.placeLimit && (
                                          <CreateBookingItemAlert color="yellow">
                                            Ticket limit has been reached
                                          </CreateBookingItemAlert>
                                        )}
                                      {activity.placeLimit &&
                                        activityGroupSalesQueryInfo.data
                                          .activitySales[activity._id] >=
                                          activity.placeLimit && (
                                          <CreateBookingItemAlert color="yellow">
                                            Place limit has been reached
                                          </CreateBookingItemAlert>
                                        )}
                                    </>
                                  </label>
                                  <div className="space-y-1">
                                    {getIsTicketOptionChecked(
                                      ticket._id,
                                      activity._id
                                    ) &&
                                      Boolean(
                                        activityGroupQueryInfo.data.addOns.filter(
                                          addOn => addOn.enabled
                                        ).length
                                      ) && (
                                        <div className="mt-2 space-y-1">
                                          {activityGroupQueryInfo.data.addOns
                                            .filter(
                                              addOn =>
                                                addOn.enabled &&
                                                getCanAddOnBeUsedForActivity(
                                                  addOn,
                                                  activity._id
                                                )
                                            )
                                            .map(addOn => (
                                              <div
                                                key={`${ticket._id}_${activity._id}_${addOn._id}`}
                                                className="relative flex items-start justify-center sm:justify-start"
                                              >
                                                <div className="flex h-5 items-center">
                                                  <input
                                                    id={`${ticket._id}_${activity._id}_${addOn._id}`}
                                                    name={`${ticket._id}_${activity._id}_${addOn._id}`}
                                                    type="checkbox"
                                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                    onChange={e =>
                                                      handleAddonSelect(
                                                        ticket._id,
                                                        activity._id,
                                                        addOn._id,
                                                        e.target.checked
                                                      )
                                                    }
                                                  />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                  <label
                                                    htmlFor={`${ticket._id}_${activity._id}_${addOn._id}`}
                                                    className="text-gray-700"
                                                  >
                                                    {addOn.name}
                                                  </label>
                                                  <span className="ml-2 text-gray-500">
                                                    +
                                                    {formatCurrency({
                                                      rawAmount: addOn.price,
                                                      currency:
                                                        clientQueryInfo.data
                                                          .currency
                                                    })}
                                                  </span>
                                                </div>
                                              </div>
                                            ))}
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  );
                }
              })}
          </div>
          {touched && error && (
            <p className="mt-2 text-sm text-red-600">{error}</p>
          )}
        </>
      ) : null}
    </div>
  );
};

export default AdminBookingActivityTicketSelect;
