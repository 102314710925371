import { cn } from "utils/cn";
import { kebabCase } from "lodash";
import type {
  UseFieldApiConfig,
  UseFieldApiProps
} from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import IconExclamationCircleSolid from "public/images/icons/exclamation-circle_solid.svg";
import { FormTemplateType } from "types/form";
import HelpText from "../HelpText";

import { Switch } from "@headlessui/react";
import { DiscountRuleType } from "types/model/discount-rule";

export interface DiscountRuleDateRangeValue {
  shouldLimitDatePeriod: boolean;
  specifyStartDate: boolean;
  startDate?: string;
  specifyEndDate: boolean;
  endDate?: string;
  inModal?: boolean;
  formTemplate?: FormTemplateType;
  discountRuleType?: DiscountRuleType;
}

const DiscountRuleDateRange = (
  props: UseFieldApiConfig
): React.ReactElement => {
  const {
    label,
    input,
    isRequired,
    meta: { error, touched },
    index,
    helpText,
    arrayField,
    inModal,
    formTemplate = FormTemplateType.Default,
    discountRuleType,
    isSubFormSubmitted
  }: UseFieldApiProps<DiscountRuleDateRangeValue, HTMLElement> = useFieldApi(
    props
  );

  const isDefaultFormTemplate = formTemplate === FormTemplateType.Default;
  const isSeamlessFormTemplate = formTemplate === FormTemplateType.Seamless;
  const isFirstItem = index === 0;

  const touchedOrSubFormSubmitted = touched || isSubFormSubmitted;

  const handleChange = e => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    input.onChange({
      ...input.value,
      [e.target.name]: value
    });
  };

  return (
    <div
      className={cn(
        !inModal &&
          isDefaultFormTemplate &&
          "sm:grid sm:grid-cols-3 sm:items-start sm:gap-4",
        isDefaultFormTemplate &&
          !arrayField &&
          !isFirstItem &&
          "mt-5 border-t border-gray-200 pt-5",
        (isSeamlessFormTemplate || arrayField) && !isFirstItem && "mt-5"
      )}
    >
      <label
        className="hidden text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:block sm:pt-2"
        htmlFor={input.name}
      >
        {label}
        {isRequired && "*"}
      </label>
      <div
        className={cn(
          isSeamlessFormTemplate && "mt-2",
          isDefaultFormTemplate && "mt-1 sm:col-span-2" //  sm:max-w-sm
        )}
      >
        <div className="space-y-5">
          <div>
            <Switch.Group as="div" className="flex items-center">
              <Switch
                checked={input.value.shouldLimitDatePeriod}
                name="shouldLimitDatePeriod"
                onChange={value =>
                  handleChange({
                    target: { value, name: "shouldLimitDatePeriod" }
                  })
                }
                className={cn(
                  input.value.shouldLimitDatePeriod
                    ? "bg-indigo-600"
                    : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                )}
              >
                <span
                  aria-hidden="true"
                  className={cn(
                    input.value.shouldLimitDatePeriod
                      ? "translate-x-5"
                      : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
              <Switch.Label as="span" className="ml-3">
                <span className="text-sm font-medium text-gray-900">
                  {discountRuleType === DiscountRuleType.Code
                    ? "Limit the date range when customers can redeem this discount"
                    : "Limit the date range when this discount rule will be applied to bookings"}
                </span>
              </Switch.Label>
            </Switch.Group>

            {input.value.shouldLimitDatePeriod && (
              <div className="mt-5 space-y-3 sm:max-w-sm">
                <div>
                  <div className="relative mt-1 flex items-start sm:col-span-2 sm:max-w-sm">
                    <div className="absolute flex h-5 items-center">
                      <input
                        id="specifyStartDate"
                        name="specifyStartDate"
                        type="checkbox"
                        className="form-checkbox h-4 w-4 rounded text-indigo-600 focus:ring-indigo-500"
                        checked={Boolean(input.value.specifyStartDate)}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="pl-7 text-sm">
                      <label
                        htmlFor="specifyStartDate"
                        className="font-medium text-gray-700"
                      >
                        Specify start date
                      </label>
                    </div>
                  </div>

                  {input.value.specifyStartDate && (
                    <div className="relative mt-3">
                      <input
                        id="startDate"
                        name="startDate"
                        type="date"
                        required
                        className={cn(
                          "block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm",
                          touchedOrSubFormSubmitted &&
                            error &&
                            "focus:shadow-outline-red border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-300"
                        )}
                        value={input.value.startDate}
                        data-cy="input-start-date"
                        onChange={handleChange}
                      />
                      {touchedOrSubFormSubmitted && error && (
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <IconExclamationCircleSolid
                            width={20}
                            height={20}
                            className="h-5 w-5 text-red-500"
                            data-cy={`icon-exclamation-${kebabCase(label)}`}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div>
                  <div className="relative mt-1 flex items-start sm:col-span-2 sm:max-w-sm">
                    <div className="absolute flex h-5 items-center">
                      <input
                        id="specifyEndDate"
                        name="specifyEndDate"
                        type="checkbox"
                        className="form-checkbox h-4 w-4 rounded text-indigo-600 focus:ring-indigo-500"
                        checked={Boolean(input.value.specifyEndDate)}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="pl-7 text-sm">
                      <label
                        htmlFor="specifyEndDate"
                        className="font-medium text-gray-700"
                      >
                        Specify end date
                      </label>
                    </div>
                  </div>

                  {input.value.specifyEndDate && (
                    <div className="relative mt-3">
                      <input
                        id="endDate"
                        name="endDate"
                        type="date"
                        required
                        className={cn(
                          "block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm",
                          touchedOrSubFormSubmitted &&
                            error &&
                            "focus:shadow-outline-red border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-300"
                        )}
                        value={input.value.endDate}
                        data-cy="input-end-date"
                        onChange={handleChange}
                      />
                      {touchedOrSubFormSubmitted && error && (
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <IconExclamationCircleSolid
                            width={20}
                            height={20}
                            className="h-5 w-5 text-red-500"
                            data-cy={`icon-exclamation-${kebabCase(label)}`}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        {helpText && <HelpText label={label} helpText={helpText} />}
        {touchedOrSubFormSubmitted && error && (
          <p
            className="mt-2 text-sm text-red-600"
            data-cy={`error-${kebabCase(label)}`}
          >
            {error}
          </p>
        )}
      </div>
    </div>
  );
};

export default DiscountRuleDateRange;
