import { useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { cn } from "utils/cn";
import { kebabCase } from "lodash";
import pluralize from "pluralize";
import type {
  UseFieldApiConfig,
  UseFieldApiProps
} from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import { FormTemplateType } from "types/form";
import type { FileAttachment } from "types/model/file-attachment";
import {
  CloudArrowUpIcon,
  DocumentIcon,
  PaperClipIcon
} from "@heroicons/react/24/outline";
import HelpText from "../HelpText";
import { UploadType } from "types/model/field";
import Badge from "components/Badge";

interface FileUploadProps
  extends UseFieldApiProps<FileAttachment[], HTMLElement> {
  label?: string;
  index?: number;
  isRequired?: boolean;
  helpText?: string;
  inModal?: boolean;
  formTemplate?: FormTemplateType;
  arrayField?: boolean;
  uploadType?: UploadType;
}

const FileUpload = (props: UseFieldApiConfig): React.ReactElement => {
  const {
    label,
    input,
    isRequired,
    isAdminOnly,
    meta: { error, touched },
    maxFiles,
    helpText,
    index,
    formFieldName = "attachment",
    uploadType,
    arrayField,
    formTemplate = FormTemplateType.Default
  }: FileUploadProps = useFieldApi(props);

  const isDefaultFormTemplate = formTemplate === FormTemplateType.Default;
  const isSeamlessFormTemplate = formTemplate === FormTemplateType.Seamless;
  const isFirstItem = index === 0;

  const uploadPath =
    uploadType === UploadType.Document
      ? "/api/file-uploads/file-upload-document"
      : "/api/file-uploads/file-upload-image";

  const [isUploading, setIsUploading] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const onDrop = async (acceptedFiles: File[]): Promise<void> => {
    if (maxFiles && input.value.length >= maxFiles) {
      setErrMsg(
        `You can only upload up to ${maxFiles} ${pluralize("file", maxFiles)}`
      );
      return;
    }
    if (!acceptedFiles.length) {
      setErrMsg("Unable to upload this file");
      return;
    }
    setErrMsg("");
    setIsUploading(true);
    const data = new FormData();
    data.append(formFieldName, acceptedFiles[0]);
    try {
      const response = await axios.post(uploadPath, data);
      input.onChange([...input.value, response.data]);
      setIsUploading(false);
    } catch (error) {
      setErrMsg(error?.response?.data?.message || "Error uploading file");
      setIsUploading(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    ...(uploadType === UploadType.Image && {
      // JPEG, PNG or GIF
      accept: {
        "image/jpeg": [".jpeg", ".jpg"],
        "image/png": [".png"],
        "image/gif": [".gif"]
      }
    }),
    ...(uploadType === UploadType.Document && {
      // PDF, DOCX, XLSX, PPTX, TXT or CSV
      accept: {
        "application/pdf": [".pdf"],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          [".docx"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx"
        ],
        "application/vnd.openxmlformats-officedocument.presentationml.presentation":
          [".pptx"],
        "text/plain": [".txt"],
        "text/csv": [".csv"]
      }
    }),
    maxSize: 4.5e6,
    multiple: false
  });

  const removeFile = (id: string): void => {
    const updatedAttachments = input.value.filter(
      attachment => attachment._id !== id
    );
    input.onChange(updatedAttachments);
  };

  return (
    <div
      className={cn(
        isDefaultFormTemplate &&
          "sm:grid sm:grid-cols-3 sm:items-start sm:gap-4",
        isDefaultFormTemplate &&
          !arrayField &&
          !isFirstItem &&
          "mt-5 border-t border-gray-200 pt-5",
        (isSeamlessFormTemplate || arrayField) && !isFirstItem && "mt-5"
      )}
    >
      <label
        className={cn(
          "text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2",
          !isAdminOnly && "block",
          isAdminOnly && "flex flex-wrap gap-2"
        )}
        htmlFor={input.name}
      >
        {label}
        {isRequired && "*"}
        {isAdminOnly && <Badge>Admin only</Badge>}
      </label>

      <div className="mt-1 sm:col-span-2">
        {input.value && input.value.length > 0 && (
          <ul
            className={cn(
              "mt-2 rounded-md border border-gray-200 sm:mt-0",
              (!maxFiles || maxFiles > input.value.length) && "mb-4"
            )}
          >
            {input.value.map((file: FileAttachment, index: number) => (
              <li
                key={file._id}
                className={cn(
                  "flex items-center justify-between py-3 pl-3 pr-4 text-sm",
                  index > 0 && "border-t border-gray-200"
                )}
              >
                <div className="flex w-0 flex-1 items-center">
                  {uploadPath.includes("file-uploads/file-upload") ? (
                    <DocumentIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                  ) : (
                    <PaperClipIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                  )}
                  <span className="ml-2 w-0 flex-1 truncate">
                    {file.originalname}
                  </span>
                </div>
                <div className="ml-4 flex-shrink-0">
                  <a
                    href={
                      file.isAuthenticated && !file._id.startsWith("temp_")
                        ? `/api/asset?fileId=${file._id}`
                        : file.path
                    }
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                    download
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Download
                  </a>
                  {" | "}
                  <a
                    onClick={(): void => removeFile(file._id)}
                    className="cursor-pointer font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Remove
                  </a>
                </div>
              </li>
            ))}
          </ul>
        )}
        {errMsg && (
          <div className="mb-2 rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg
                  className="h-5 w-5 text-red-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">{errMsg}</h3>
              </div>
            </div>
          </div>
        )}
        {(!maxFiles || input.value.length < maxFiles) && (
          <div
            {...getRootProps()}
            className={cn(
              "flex max-w-lg justify-center rounded-md border-2 border-gray-300 px-6 pb-6 pt-5 focus:border-indigo-500 focus:ring-indigo-500",
              !isDragActive && "border-dashed"
            )}
          >
            <div className="relative space-y-1 text-center">
              {isUploading && (
                <svg
                  className={cn(
                    "absolute inset-0 m-auto h-10 w-10 animate-spin text-indigo-600"
                  )}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
              <div className={cn(isUploading && "invisible")}>
                <input {...getInputProps()} />
                <CloudArrowUpIcon className="mx-auto h-12 w-12 text-gray-400" />
                <div className="flex text-sm text-gray-600">
                  <p className="mx-auto">Click to upload or drag and drop</p>
                </div>
                {uploadType === UploadType.Image && (
                  <p className="text-xs text-gray-500">
                    PNG, JPG, GIF up to 4.5MB
                  </p>
                )}
                {uploadType === UploadType.Document && (
                  <p className="text-xs text-gray-500">
                    PDF, DOCX, XLSX, PPTX, TXT, CSV up to 4.5MB
                  </p>
                )}
              </div>
            </div>
          </div>
        )}
        {helpText && <HelpText label={label} helpText={helpText} />}
        {touched && error && (
          <p
            className="mt-2 text-sm text-red-600"
            data-cy={`error-${kebabCase(label)}`}
          >
            {error}
          </p>
        )}
      </div>
    </div>
  );
};

export default FileUpload;
