import type { AxiosError, AxiosResponse } from "axios";
import axios from "axios";
import type { UpdateConnectAccountStatusData } from "pages/api/stripe/update-connected-account-status";
import type { MutationResultPair} from "react-query";
import { queryCache, useMutation } from "react-query";
import type { ErrorResponse } from "types";

export default function useUpdateStripeConnectedAccountStatus(): MutationResultPair<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateConnectAccountStatusData,
  unknown
> {
  return useMutation(
    data => {
      return axios
        .post("/api/stripe/update-connected-account-status", data)
        .catch(err => {
          throw new Error(err.response.data.message);
        });
    },
    {
      onSuccess: (_, originalData) => {
        queryCache.invalidateQueries([
          "paymentMethod",
          originalData.paymentMethodId
        ]);
      }
    }
  );
}
