import type {
  UseFieldApiProps,
  ValidatorType
} from "@data-driven-forms/react-form-renderer";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import useFormApi from "@data-driven-forms/react-form-renderer/use-form-api";
import AutosuggestHighlightMatch from "autosuggest-highlight/match";
import AutosuggestHighlightParse from "autosuggest-highlight/parse";
import { escapeRegexCharacters } from "helpers/string";
import IconExclamationCircleSolid from "public/images/icons/exclamation-circle_solid.svg";
import type { ComponentProps, FormEvent } from "react";
import { useState } from "react";
import Autosuggest from "react-autosuggest";
import type { Change, FormState } from "types";
import { cn } from "utils/cn";
import HelpText from "../HelpText";

type Suggestion = object & {
  _id: string;
  enabled: boolean;
};

type Props = Partial<ComponentProps<typeof Autosuggest>> & {
  id: string;
  label: string;
  name: string;
  isRequired?: boolean;
  helpText?: string;
  options: Suggestion[];
  primaryField: string;
  secondaryField?: string;
  tertiaryField?: string;
  input: UseFieldApiProps<unknown, HTMLElement>["input"];
  onChangeHandler?: (formState: FormState, change: Change) => void;
  shouldAddDisabledIndicator: boolean;
  validate: ValidatorType[];
};

export const Autocomplete = ({
  options,
  primaryField,
  secondaryField,
  tertiaryField,
  shouldRenderSuggestions,
  defaultValue,
  ...props
}: Props) => {
  const {
    label,
    input,
    isRequired,
    meta: { error, touched },
    index,
    helpText,
    arrayField,
    onChange,
    onChangeHandler,
    shouldAddDisabledIndicator,
    fieldsToMatchOn = ["primaryField"],
    ...rest
  } = useFieldApi<string>(props);

  const { getState, change } = useFormApi();
  const formState = getState();

  const [inputValue, setInputValue] = useState(null);
  const [suggestions, setSuggestions] = useState([]);

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = (suggestion: Suggestion): string =>
    suggestion[primaryField];

  const renderSuggestion = (
    suggestion: Suggestion,
    { query }: Autosuggest.RenderSuggestionParams
  ) => {
    const matches = AutosuggestHighlightMatch(suggestion[primaryField], query);
    const parts = AutosuggestHighlightParse(suggestion[primaryField], matches);

    const matchesSecondary = AutosuggestHighlightMatch(
      suggestion[secondaryField],
      query
    );
    const partsSecondary = AutosuggestHighlightParse(
      suggestion[secondaryField],
      matchesSecondary
    );
    const matchesTertiary = AutosuggestHighlightMatch(
      suggestion[tertiaryField],
      query
    );
    const partsTertiary = AutosuggestHighlightParse(
      suggestion[tertiaryField],
      matchesTertiary
    );

    return (
      <div className="cursor-default py-2 pl-3 pr-9 text-gray-900 hover:bg-indigo-600 hover:text-white">
        {fieldsToMatchOn.includes("primaryField") ? (
          <div>
            {parts.map((part, index) => (
              <span className={cn(part.highlight && "font-bold")} key={index}>
                {part.text}
              </span>
            ))}
            {!suggestion.enabled && shouldAddDisabledIndicator && " (Disabled)"}
          </div>
        ) : (
          <div className="text-sm">{suggestion[primaryField]}</div>
        )}
        {secondaryField && (
          <>
            {fieldsToMatchOn.includes("secondaryField") ? (
              <div>
                {partsSecondary.map((part, index) => (
                  <span
                    className={cn(part.highlight && "font-bold")}
                    key={index}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            ) : (
              <div className="text-sm">{suggestion[secondaryField]}</div>
            )}
          </>
        )}
        {tertiaryField && (
          <>
            {fieldsToMatchOn.includes("tertiaryField") ? (
              <div>
                {partsTertiary.map((part, index) => (
                  <span
                    className={cn(part.highlight && "font-bold")}
                    key={index}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            ) : (
              <div className="text-sm">{suggestion[tertiaryField]}</div>
            )}
          </>
        )}
      </div>
    );
  };

  const onSuggestionsFetchRequested = ({
    value
  }: Autosuggest.SuggestionsFetchRequestedParams) => {
    const escapedValue = escapeRegexCharacters(value.trim());

    const suggestions = options.filter(item => {
      const parts = escapedValue.split(" ");

      const doesEveryWordMatch = parts.every(part => {
        const searchRegex = new RegExp(`\\b${part}`, "i");
        const doesItMatch =
          (fieldsToMatchOn.includes("primaryField") &&
            searchRegex.test(item[primaryField])) ||
          (fieldsToMatchOn.includes("secondaryField") &&
            searchRegex.test(item[secondaryField])) ||
          (fieldsToMatchOn.includes("tertiaryField") &&
            searchRegex.test(item[tertiaryField]));

        return doesItMatch;
      });

      return doesEveryWordMatch;
    });

    setSuggestions(suggestions);
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onChangeAutocompleteField = (
    _: FormEvent<HTMLElement>,
    { newValue }: Autosuggest.ChangeEvent
  ): void => {
    input.onChange(undefined);
    onChange && onChange(undefined);
    setInputValue(newValue);
  };

  const onSuggestionSelected = (
    _: FormEvent<HTMLElement>,
    { suggestion }: Autosuggest.SuggestionSelectedEventData<Suggestion>
  ): void => {
    input.onChange(suggestion._id);
    onChange && onChange(suggestion._id);

    onChangeHandler?.(formState, change);
  };

  const renderSuggestionsContainer = ({
    containerProps,
    children
  }: Autosuggest.RenderSuggestionsContainerParams) => {
    return (
      <div
        {...containerProps}
        key={containerProps.id}
        className="absolute z-30 mt-1 w-full rounded-md bg-white shadow-lg"
      >
        {children}
      </div>
    );
  };

  const getInputValue = (): string => {
    if (inputValue) return inputValue;

    const value = options?.find(
      item => item._id === (input.value || defaultValue)
    );

    if (value) {
      return value[primaryField];
    } else {
      return "";
    }
  };

  return (
    <div
      className={cn(
        "sm:grid sm:grid-cols-3 sm:items-start sm:gap-4",
        !arrayField && index !== 0 && "mt-5 border-t border-gray-200 pt-5",
        arrayField && index !== 0 && "mt-5"
      )}
    >
      <label
        className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
        htmlFor={input.name}
      >
        {label}
        {isRequired && "*"}
      </label>
      <div className="mt-1 sm:col-span-2 sm:max-w-sm">
        <div className="relative">
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={{
              id: input.name,
              className: cn(
                "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md",
                touched &&
                  error &&
                  "pr-10 border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
              ),
              value: getInputValue(),
              onChange: onChangeAutocompleteField,
              ...rest
            }}
            onSuggestionSelected={onSuggestionSelected}
            renderSuggestionsContainer={renderSuggestionsContainer}
            shouldRenderSuggestions={shouldRenderSuggestions}
          />
          {touched && error && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <IconExclamationCircleSolid
                width={20}
                height={20}
                className="h-5 w-5 text-red-500"
              />
            </div>
          )}
        </div>
        {helpText && <HelpText label={label} helpText={helpText} />}
        {touched && error && (
          <p className="mt-2 text-sm text-red-600">{error}</p>
        )}
      </div>
    </div>
  );
};
