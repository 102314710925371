import slugify from "slugify";
import type { Field, Field as FieldModel} from "types/model/field";
import { FieldType } from "types/model/field";
import type { Client} from "types/model/client";
import { ListingType } from "types/model/client";
import type {
  Schema} from "@data-driven-forms/react-form-renderer";
import {
  componentTypes,
  validatorTypes
} from "@data-driven-forms/react-form-renderer";
import { getCanFieldBeUsedAsHomePageFilter } from "helpers/field";
import type { HomeTab} from "types/model/home-tab";
import { HomeTabType } from "types/model/home-tab";
import { FormTemplateType } from "types/form";
import { getFieldOptions } from "./admin-user";
import type { Venue } from "types/model/venue";
import type { FieldOption } from "types/model/field-option";

export const getHomeTabsConfigInitialValue = (
  homeTabs: HomeTab<Field, FieldOption, Venue>[]
) => {
  const homeTabsInitialValue = homeTabs.map(homeTab => {
    if (
      !homeTab._id.includes("temp_") &&
      homeTab.type === HomeTabType.FieldOption
    ) {
      return {
        ...homeTab,
        field: homeTab.field._id,
        fieldOptions: homeTab.fieldOptions.map(item => item._id)
      };
    } else if (
      !homeTab._id.includes("temp_") &&
      homeTab.type === HomeTabType.Venue
    ) {
      return {
        ...homeTab,
        field: homeTab.field._id,
        venues: homeTab.venues.map(item => item._id)
      };
    } else {
      return homeTab;
    }
  });
  return homeTabsInitialValue;
};

interface GenerateHomePageDisplayFormSchemaData {
  activityFieldsData: FieldModel[];
  venuesData: Venue[];
  homeTabs: HomeTab<Field, FieldOption, Venue>[];
  client: Client;
}

export const generateHomePageDisplayFormSchema = ({
  activityFieldsData,
  venuesData,
  homeTabs,
  client
}: GenerateHomePageDisplayFormSchemaData): Schema => {
  const schema = {
    fields: [
      {
        index: 0,
        component: "section-header",
        name: "listingDisplayHeader",
        title: "Listing display",
        description:
          "You can choose to display a list of activities in date order or a diary list of every activity session."
      },
      {
        index: 1,
        arrayField: false,
        component: "radio-group-with-description-plain",
        inputType: "number",
        name: "listingType",
        label: "Display type",
        isRequired: true,
        validate: [{ type: "required" }],
        options: [
          {
            value: ListingType.Activities,
            label: "Activities list",
            description:
              "If selected, a list of activities will be displayed in date order."
          },
          {
            value: ListingType.Sessions,
            label: "Sessions list",
            description:
              "If selected, every session of an activity will be listed separately, grouped by the date they take place on."
          }
        ],
        initialValue: client.homePageDisplay.listingType
      },
      {
        index: 2,
        component: "section-header",
        name: "homePageSettingsFiltersHeader"
      },
      {
        index: 3,
        arrayField: false,
        component: "checkbox-multiple",
        name: "filters",
        label: "Enabled filters",
        isRequired: false,
        options: activityFieldsData
          .filter(field => getCanFieldBeUsedAsHomePageFilter(field.type))
          .map(field => ({
            value: field._id,
            label: field.title
          })),
        initialValue:
          activityFieldsData
            .filter(field => getCanFieldBeUsedAsHomePageFilter(field.type))
            .filter(field => field.useForSiteFiltering)
            .map(field => field._id) || []
      },
      {
        index: 4,
        component: "section-header",
        name: "tabsHeader",
        title: "Tabs",
        description:
          "Optionally add tabs to the home page to display pre-filtered listings."
      },
      {
        index: 5,
        component: "home-tabs-config",
        name: "homeTabs",
        label: "Tabs",
        activityFieldsData,
        venuesData,
        initialValue: getHomeTabsConfigInitialValue(homeTabs)
      }
    ]
  };

  return schema;
};

const getInitialHomeTabValues = (homeTab: HomeTab) => {
  if (homeTab?.type === HomeTabType.FieldOption) {
    return homeTab.fieldOptions;
  } else if (homeTab?.type === HomeTabType.Venue) {
    return homeTab.venues;
  }
  return null;
};

interface GenerateHomeTabFormSchemaData {
  formTemplate?: FormTemplateType;
  activityFieldsData: Field[];
  venuesData: Venue[];
  prefix: string;
  existingHomeTab?: HomeTab;
  existingTabPathValues?: string[];
}

export const generateHomeTabFormSchema = ({
  formTemplate = FormTemplateType.Seamless,
  activityFieldsData,
  venuesData,
  prefix,
  existingHomeTab,
  existingTabPathValues
}: GenerateHomeTabFormSchemaData) => {
  const fieldsTheCanBeUsedAsFilters = activityFieldsData.filter(
    field =>
      ((field.type === FieldType.Venue && venuesData.length) ||
        ([FieldType.SelectList, FieldType.CheckboxMultiple].includes(
          field.type
        ) &&
          field.fieldOptions.length)) &&
      field.enabled
  );

  const fields = [
    {
      index: 0,
      arrayField: false,
      component: componentTypes.TEXT_FIELD,
      name: `${prefix}.name`,
      label: "Display name",
      isRequired: true,
      validate: [{ type: "required" }],
      formTemplate,
      helpText: "We suggest keeping it short.",
      initialValue: existingHomeTab?.name
    },
    {
      index: 1,
      arrayField: false,
      component: componentTypes.TEXT_FIELD,
      name: `${prefix}.path`,
      label: "Path",
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED
        },
        {
          type: validatorTypes.PATTERN,
          pattern: /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
          message: "Path is invalid"
        },
        value =>
          existingTabPathValues?.includes(value)
            ? "Path has already been used for another tab."
            : undefined
      ],
      helpText:
        "This will create a unique url for each tab, which you can share separately. Only lowercase letters, numbers and dashes are allowed.",
      formTemplate,
      initialValue: existingHomeTab?.path,
      prefix: "/listing/",
      resolveProps: (_props, _field, formOptions) => {
        const nameValue = formOptions.getState().values[prefix]?.name;
        if (nameValue && !existingHomeTab?.path) {
          return {
            initialValue: slugify(nameValue, { lower: true, strict: true })
          };
        }
      }
    },
    {
      index: 2,
      arrayField: true,
      component: componentTypes.SELECT,
      name: `${prefix}.field`,
      label: "Field",
      isRequired: true,
      inModal: true,
      validate: [
        {
          type: "required"
        }
      ],
      helpText: "Field to be pre-filtered for the tab",
      formTemplate,
      options: fieldsTheCanBeUsedAsFilters.map(field => ({
        value: field._id,
        label: field.title
      })),
      initialValue: existingHomeTab?.field
    },
    {
      index: 3,
      arrayField: true,
      component: "checkbox-multiple",
      name: `${prefix}.value`,
      label: "Value",
      isRequired: true,
      validate: [
        {
          type: "required",
          message: "At least one option should be selected."
        }
      ],
      formTemplate,
      resolveProps: (_props, _field, formOptions) => {
        const fieldId = formOptions.getState().values[prefix]?.field;
        const field = fieldsTheCanBeUsedAsFilters.find(
          field => field._id === fieldId
        );
        return {
          options: getFieldOptions(field, venuesData)
        };
      },
      initialValue: getInitialHomeTabValues(existingHomeTab)
    }
  ];

  const schema = {
    fields
  };

  return schema;
};
