import type {
  UseFieldApiConfig,
  UseFieldApiProps
} from "@data-driven-forms/react-form-renderer";
import { FormSpy, useFieldApi } from "@data-driven-forms/react-form-renderer";
import { Alert } from "components/Alert";
import { AttendeeAgeWarning } from "features/attendees/components/AttendeeAgeWarning";
import { useCheckAgeEligibility } from "features/attendees/hooks/useCheckAgeEligibility";
import { renderActivityDateString } from "helpers/helpers";
import type { Activity, Ticket } from "types/model/activity";
import type { ActivityGroup } from "types/model/activity-group";
import { WaitlistType } from "types/model/activity-group";
import type { Attendee } from "types/model/attendee";
import type { Client } from "types/model/client";
import type { SubscriptionPlan } from "types/model/subscription-plan";
import type { WaitlistEntry } from "types/model/waitlistEntry";
import { cn } from "utils/cn";

type Props = UseFieldApiProps<unknown, HTMLElement> & {
  sessions?: {
    ticket: Ticket<SubscriptionPlan>;
    activities: Activity<string>[];
  }[];
  client?: Client;
  activityGroup?: ActivityGroup;
  userWaitlistEntries?: WaitlistEntry[];
  attendees?: Attendee[];
  currentAttendee: string;
};

/**
 * Used to select sessions from a list of sessions. Used in the join waitlist
 * form modal. This component is styled uniquely to fit the modal.
 */
const WaitlistSessionOptions = (props: Props) => {
  const {
    label,
    required,
    meta: { error, touched },
    input,
    sessions = [],
    client,
    activityGroup,
    userWaitlistEntries,
    currentAttendee,
    attendees = []
  } = props;

  const data = sessions[0];

  const options = data.activities.map(option => {
    const userEntry = userWaitlistEntries.find(
      entry =>
        entry?.activity?._id === option._id &&
        entry.attendee?._id === currentAttendee
    );
    const joinedWaitlist =
      activityGroup.waitlistType === WaitlistType.SingleSession && !!userEntry;

    return {
      value: option._id,
      label: renderActivityDateString({
        activityDate: option.date,
        dateOnly: false,
        timeOnly: false,
        timeZone: client.timeZone,
        includeYear: false,
        timeOverride: data.ticket.timeDisplay
      }),
      disabled: joinedWaitlist
    };
  });

  const waitlistType = activityGroup.waitlistType;

  const joinedAllSingleSessionsWaitlist =
    waitlistType === WaitlistType.SingleSession &&
    userWaitlistEntries.length > 0 &&
    userWaitlistEntries.filter(entry => entry.attendee?._id === currentAttendee)
      .length === options.length;

  const handleChange = (value: string) => {
    const currentValue = Array.isArray(input.value) ? input.value : [];
    const newValue = currentValue.includes(value)
      ? currentValue.filter(v => v !== value)
      : [...currentValue, value];
    input.onChange(newValue);
  };

  const hasError = touched && error;

  const { hasBlock } = useCheckAgeEligibility({
    userAttendees: attendees,
    currentAttendee,
    activityGroup,
    sessions: data.activities
  });

  return (
    <div className="mt-4 flex flex-col gap-1">
      <p
        className={cn(
          "mt-px block pt-2 text-sm font-medium leading-5 text-gray-700 sm:mt-0 sm:pt-0",
          (!currentAttendee || joinedAllSingleSessionsWaitlist) && "opacity-50"
        )}
      >
        {label}
        {required && "*"}
      </p>
      <div className="flex flex-col gap-1">
        {options.map(option => {
          const alreadyJoined = !!userWaitlistEntries.find(
            entry =>
              entry.activity?._id === option.value &&
              entry.attendee?._id === currentAttendee
          );

          const checked =
            (Array.isArray(input.value) &&
              input.value.includes(option.value)) ||
            alreadyJoined;

          return (
            <div key={option.value} className="flex items-center">
              <input
                type="checkbox"
                id={option.value}
                value={option.value}
                name={input.name}
                disabled={option.disabled || !currentAttendee || hasBlock}
                checked={checked}
                onChange={() => handleChange(option.value)}
                className={cn(
                  "h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500",
                  (alreadyJoined || hasBlock) && "opacity-50"
                )}
              />
              <label
                htmlFor={option.value}
                className={cn(
                  "ml-2 block text-sm text-gray-900",
                  (option.disabled || !currentAttendee || hasBlock) &&
                    "opacity-50"
                )}
              >
                {option.label}
                {option.disabled && (
                  <span className="text-xs text-gray-500">
                    {" "}
                    (Joined waitlist)
                  </span>
                )}
              </label>
            </div>
          );
        })}
        {joinedAllSingleSessionsWaitlist && (
          <Alert className="mt-1">
            The attendee has already joined every waitlist for this activity.
          </Alert>
        )}
        <AttendeeAgeWarning
          userAttendees={attendees}
          currentAttendee={currentAttendee}
          activityGroup={activityGroup}
          sessions={data.activities}
        />
      </div>
      {hasError && <div className="mt-1 text-sm text-red-500">{error}</div>}
    </div>
  );
};

/**
 * Used to select sessions from a list of sessions. Used in the join waitlist
 * form modal. This component is styled uniquely to fit the modal.
 */
export const SelectWaitlistSessions = (props: UseFieldApiConfig) => {
  const fieldProps = useFieldApi(props);

  return (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => (
        <WaitlistSessionOptions
          {...fieldProps}
          currentAttendee={values.attendee}
        />
      )}
    </FormSpy>
  );
};
