import type { AxiosResponse } from "axios";
import axios from "axios";
import type { MutationResultPair} from "react-query";
import { queryCache, useMutation } from "react-query";
import type { ErrorResponse } from "types";
import type { DiscountRule, DiscountRuleFormData } from "types/model/discount-rule";

export default function useCreateDiscountRule(): MutationResultPair<
  AxiosResponse<DiscountRule>,
  ErrorResponse,
  DiscountRuleFormData<string[]>,
  unknown
> {
  return useMutation(
    async data => {
      try {
        return await axios.post("/api/discount-rules", data);
      } catch (err) {
        throw new Error(err.response.data.message);
      }
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries("discountRules");
      }
    }
  );
}
