import type { UseFieldApiConfig } from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import Badge from "components/Badge";
import { kebabCase } from "lodash";
import IconExclamationCircleSolid from "public/images/icons/exclamation-circle_solid.svg";
import { FormTemplateType } from "types/form";
import type { Client } from "types/model/client";
import type { FormOption } from "types/model/form";
import { cn } from "utils/cn";
import HelpText from "../HelpText";

type SelectProps = UseFieldApiConfig & {
  arrayField?: boolean;
  client?: Client;
  emptyOptionLabel?: string;
  formTemplate?: FormTemplateType;
  helpText?: string;
  index?: number;
  inModal?: boolean;
  isAdminOnly?: boolean;
  isRequired?: boolean;
  isSubFormSubmitted?: boolean;
  label: string;
  options?: FormOption[];
};

/**
 * Select component that renders a select dropdown. Used in data-driven forms.
 */
export default function Select(props: SelectProps) {
  const {
    arrayField,
    emptyOptionLabel,
    formTemplate = FormTemplateType.Default,
    helpText,
    index,
    inModal,
    input,
    isAdminOnly,
    isRequired,
    isSubFormSubmitted,
    label,
    meta: { error, touched },
    options,
    ...rest
  } = useFieldApi(props);

  const isDefaultFormTemplate = formTemplate === FormTemplateType.Default;
  const isSeamlessFormTemplate = formTemplate === FormTemplateType.Seamless;
  const isFirstItem = index === 0;

  const touchedOrSubFormSubmitted = touched || isSubFormSubmitted;

  if (!options.length) return null;

  return (
    <div
      className={cn(
        !inModal &&
          isDefaultFormTemplate &&
          "sm:grid sm:grid-cols-3 sm:items-start sm:gap-4",
        isDefaultFormTemplate &&
          !arrayField &&
          !isFirstItem &&
          "mt-5 border-t border-gray-200 pt-5",
        (isSeamlessFormTemplate || arrayField) && !isFirstItem && "mt-5"
      )}
      data-cy={`form-field-${kebabCase(label)}`}
    >
      <label
        className={cn(
          "text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2",
          !isAdminOnly && "block",
          isAdminOnly && "flex flex-wrap gap-2"
        )}
        htmlFor={input.name}
      >
        {label}
        {isRequired && "*"}
        {isAdminOnly && <Badge>Admin only</Badge>}
      </label>
      <div className={cn(isDefaultFormTemplate && "sm:col-span-2 sm:max-w-sm")}>
        <div className="relative">
          <select
            id={input.name}
            className={cn(
              "mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm",
              touchedOrSubFormSubmitted &&
                error &&
                "focus:shadow-outline-red border-red-300 text-red-900 placeholder-red-300 focus:border-red-300"
            )}
            data-cy={`select-${kebabCase(label)}`}
            {...input}
            {...rest}
          >
            <option value="">{emptyOptionLabel ?? "Please select"}</option>
            {options.map(({ value, label }, i) => {
              return (
                <option key={i} value={value}>
                  {label}
                </option>
              );
            })}
          </select>
          {touchedOrSubFormSubmitted && error && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <IconExclamationCircleSolid
                width={20}
                height={20}
                className="h-5 w-5 bg-white text-red-500"
              />
            </div>
          )}
        </div>
        {helpText && <HelpText label={label} helpText={helpText} />}
        {touchedOrSubFormSubmitted && error && (
          <p className="mt-2 text-sm text-red-600">{error}</p>
        )}
      </div>
    </div>
  );
}
