import axios from "axios";
import type { MutationResultPair} from "react-query";
import { queryCache, useMutation } from "react-query";
import type { ErrorResponse } from "types";
import type { FormDataItem } from "types/form";
import type { Attendee } from "types/model/attendee";

interface SaveAttendeeResponse {
  data: Attendee;
}

interface SaveAttendeeData {
  id: string;
  formData: FormDataItem[];
}

export default function useSaveAttendee(): MutationResultPair<
  SaveAttendeeResponse,
  ErrorResponse,
  SaveAttendeeData,
  unknown
> {
  return useMutation(
    data => {
      return axios.put(`/api/attendees/${data.id}`, data.formData);
    },
    {
      onSuccess: async response => {
        queryCache.setQueryData(["attendee", response.data._id], response.data);
        queryCache.invalidateQueries(["userAttendees", response.data.user._id]);
      }
    }
  );
}
