import type { FieldFilters } from "types/model/field-data";

/** Remove `#` from booking numbers. */
export function handleStringifyFilters(filters: FieldFilters): string {
  if (filters.bookingNumber) {
    filters.bookingNumber = filters.bookingNumber.replace("#", "");
  }

  return JSON.stringify(filters);
}
