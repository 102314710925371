import { cn } from "utils/cn";
import { kebabCase } from "lodash";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import IconExclamationCircleSolid from "public/images/icons/exclamation-circle_solid.svg";
import { FormTemplateType } from "types/form";
import HelpText from "../HelpText";

const Duration = (props): React.ReactElement => {
  const {
    label,
    input,
    isRequired,
    meta: { error, touched },
    index,
    helpText,
    arrayField,
    inModal,
    formTemplate = FormTemplateType.Default,
    isSubFormSubmitted,
    hideLabel = false
  } = useFieldApi(props);

  const isDefaultFormTemplate = formTemplate === FormTemplateType.Default;
  const isSeamlessFormTemplate = formTemplate === FormTemplateType.Seamless;
  const isFirstItem = index === 0;

  const touchedOrSubFormSubmitted = touched || isSubFormSubmitted;

  const handleChange = e => {
    const value = e.target.value;
    const name = e.target.name.replace(`${input.name}_`, "");
    input.onChange({
      ...input.value,
      [name]: value
    });
  };

  return (
    <div
      className={cn(
        !inModal &&
          isDefaultFormTemplate &&
          "sm:grid sm:grid-cols-3 sm:items-start sm:gap-4",
        isDefaultFormTemplate &&
          !arrayField &&
          !isFirstItem &&
          !hideLabel &&
          "mt-5 border-t border-gray-200 pt-5",
        (isSeamlessFormTemplate || arrayField) && !isFirstItem && "mt-5",
        hideLabel && !isFirstItem && "mt-4"
      )}
    >
      {!hideLabel ? (
        <label
          className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
          htmlFor={input.name}
        >
          {label}
          {isRequired && "*"}
        </label>
      ) : isDefaultFormTemplate ? (
        <label
          className="hidden sm:invisible sm:mt-px sm:block sm:pt-2"
          htmlFor={input.name}
        >
          {label}
        </label>
      ) : null}
      <div
        className={cn(
          "mt-1",
          isDefaultFormTemplate && "sm:col-span-2 sm:max-w-sm"
        )}
      >
        <div className="flex gap-4">
          <div>
            <label
              htmlFor={`${input.name}_years`}
              className="mt-1 block text-sm font-medium text-gray-700 sm:mt-0"
            >
              Years
            </label>
            <div className="relative">
              <input
                id={`${input.name}_years`}
                name={`${input.name}_years`}
                type="text"
                className={cn(
                  "mt-1 block rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:w-24 sm:text-sm",
                  touchedOrSubFormSubmitted &&
                    error &&
                    "focus:shadow-outline-red border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-300"
                )}
                data-cy={`input-${kebabCase(label)}_years`}
                value={input.value.years}
                onChange={handleChange}
                onBlur={input.onBlur}
                onFocus={input.onFocus}
              />
              {touchedOrSubFormSubmitted && error && (
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <IconExclamationCircleSolid
                    width={20}
                    height={20}
                    className="h-5 w-5 text-red-500"
                    data-cy={`icon-exclamation-${kebabCase(label)}_years`}
                  />
                </div>
              )}
            </div>
          </div>

          <div>
            <label
              htmlFor={`${input.name}_months`}
              className="mt-1 block text-sm font-medium text-gray-700 sm:mt-0"
            >
              Months
            </label>
            <div className="relative">
              <input
                id={`${input.name}_months`}
                name={`${input.name}_months`}
                type="text"
                className={cn(
                  "mt-1 block rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:w-24 sm:text-sm",
                  touchedOrSubFormSubmitted &&
                    error &&
                    "focus:shadow-outline-red border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-300"
                )}
                data-cy={`input-${kebabCase(label)}_months`}
                value={input.value.months}
                onChange={handleChange}
                onBlur={input.onBlur}
                onFocus={input.onFocus}
              />
              {touchedOrSubFormSubmitted && error && (
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <IconExclamationCircleSolid
                    width={20}
                    height={20}
                    className="h-5 w-5 text-red-500"
                    data-cy={`icon-exclamation-${kebabCase(label)}_months`}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {helpText && <HelpText label={label} helpText={helpText} />}
        {touchedOrSubFormSubmitted && error && (
          <p
            className="mt-2 text-sm text-red-600"
            data-cy={`error-${kebabCase(label)}`}
          >
            {error}
          </p>
        )}
        {/* <pre>{JSON.stringify(error)}</pre> */}
      </div>
    </div>
  );
};

export default Duration;
