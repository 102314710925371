import type { AxiosError, AxiosResponse } from "axios";
import axios from "axios";
import { getShouldNotRetry } from "helpers/auth";
import type { QueryResult} from "react-query";
import { useQuery } from "react-query";
import type { ErrorResponse } from "types";
import type { User } from "types/model/user";
import handleRequestError from "./helpers/handleRequestError";

const getUser = async (_, id: string): Promise<User> => {
  if (id === "undefined") return;
  const response: AxiosResponse<User> = await axios.get(`/api/users/${id}`);
  return response.data;
};

export default function useUser(
  id: string
): QueryResult<User, AxiosError<ErrorResponse>> {
  return useQuery(["user", id], getUser, {
    retry: (failureCount: number, error) => {
      return getShouldNotRetry(error.response?.status)
        ? false
        : failureCount < 3;
    },
    onError: error => {
      handleRequestError(error);
    },
    enabled: !!id && id !== "undefined"
  });
}
