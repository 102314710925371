import type { AxiosResponse } from "axios";
import axios from "axios";
import type { MutationResultPair} from "react-query";
import { queryCache, useMutation } from "react-query";
import type { ErrorResponse } from "types";
import type { FormDataItem } from "types/form";
import type { Attendee } from "types/model/attendee";

export default function useCreateAttendee(): MutationResultPair<
  AxiosResponse<Attendee>,
  ErrorResponse,
  FormDataItem,
  unknown
> {
  return useMutation(
    data => {
      return axios.post("/api/attendees", data);
    },
    {
      onSuccess: response => {
        queryCache.invalidateQueries(["userAttendees", response.data.user]);
      }
    }
  );
}
