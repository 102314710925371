import type { Schema } from "@data-driven-forms/react-form-renderer";
import dataTypes from "@data-driven-forms/react-form-renderer/data-types";
import type { ActivityGroupFullListItem } from "types/model/activity-group";
import type { CreateAdminBookingItemsByActivityGroup } from "types/model/admin-booking";
import type { Client } from "types/model/client";
import type { Field } from "types/model/field";
import type { UserReduced } from "types/model/user";
import type { Venue } from "types/model/venue";

interface GenerateBookingFormSchemaData {
  usersFullListData: UserReduced[];
  activityGroupsFullListData: ActivityGroupFullListItem[];
  attendeeFieldsData: Field[];
  venues: Venue[];
  activityFields: Field[];
  client: Client;
}

export const generateBookingFormSchema = ({
  usersFullListData,
  activityGroupsFullListData,
  venues,
  activityFields,
  attendeeFieldsData,
  client
}: GenerateBookingFormSchemaData): Schema => {
  const schema = {
    fields: [
      {
        component: "wizard",
        name: "admin-booking-wizard",
        steps: [
          { id: "01", name: "User" },
          { id: "02", name: "Items" },
          { id: "03", name: "Attendees" }
        ],
        fields: [
          {
            name: "user",
            title: "User",
            nextStep: "bookingItems",
            fields: [
              {
                index: 0, // prevent top padding
                component: "section-header",
                name: "userHeader",
                title: "User",
                description:
                  "Select the user who is making the booking. If the user is not in the list, please add them first.",
                customClassName: "pt-0 mt-8"
              },
              {
                index: 1,
                component: "autocomplete",
                name: "userId",
                label: "User",
                isRequired: true,
                dataType: dataTypes.STRING,
                options: usersFullListData,
                validate: [
                  {
                    type: "required"
                  }
                ],
                primaryField: "fullName",
                secondaryField: "email",
                shouldAddDisabledIndicator: true,
                onChangeHandler: clearAttendeesValuesOnUserChange
              },
              {
                index: 2,
                component: "user-details",
                name: "userDetails",
                userIdFieldName: "userId",
                condition: {
                  and: [
                    {
                      when: "userId",
                      isNotEmpty: true
                    }
                  ]
                }
              }
            ]
          },
          {
            name: "bookingItems",
            title: "Booking items",
            nextStep: "attendees",
            fields: [
              {
                index: 0, // prevent top padding
                component: "section-header",
                name: "bookingItemsHeader",
                title: "Booking items",
                description:
                  "Add the items that the user is booking. You can add multiple items.",
                customClassName: "pt-0 mt-8"
              },
              {
                index: 1,
                component: "admin-booking-items",
                name: "bookingItems",
                label: "Booking items",
                activityGroups: activityGroupsFullListData,
                venues,
                activityFields,
                client,
                validate: [
                  {
                    type: "required",
                    message: "Please add at least one booking item"
                  }
                ]
              }
            ]
          },
          {
            name: "attendees",
            title: "Attendees",
            // nextStep: 'confirm',
            fields: [
              {
                index: 0, // prevent top padding
                component: "section-header",
                name: "attendeesHeader",
                title: "Attendees",
                description:
                  "Assign attendees to the booking items. You can add multiple attendees.",
                customClassName: "pt-0 mt-8"
              },
              {
                index: 1,
                component: "admin-booking-items-assign-attendee",
                name: "attendeeAssignment",
                label: "Attendee assignment",
                activityGroups: activityGroupsFullListData,
                venues,
                activityFields,
                attendeeFields: attendeeFieldsData,
                client,
                initialValue: 0,
                validate: [
                  (value: number) => {
                    if (value === 0) {
                      return "Please assign an attendee for each booking item.";
                    }
                    return undefined;
                  }
                ]
              }
            ]
          }
          // {
          //   name: 'confirm',
          //   title: 'Confirm',
          //   fields: [
          //     {
          //       index: 0, // prevent top padding
          //       component: 'section-header',
          //       name: 'confirmHeader',
          //       title: 'Confirm',
          //       description:
          //         'Confirm the booking details and send the booking confirmation email.',
          //       customClassName: 'pt-0 mt-8',
          //     },
          //     {
          //       index: 1,
          //       arrayField: false,
          //       component: componentTypes.SWITCH,
          //       name: 'sendBookingConfirmationEmail',
          //       label: 'Send booking confirmation email',
          //       dataType: dataTypes.BOOLEAN,
          //     },
          //     {
          //       index: 2,
          //       arrayField: false,
          //       component: componentTypes.SWITCH,
          //       name: 'sendPaymentEmail',
          //       label: 'Send payment email',
          //       dataType: dataTypes.BOOLEAN,
          //     },
          //   ],
          // },
        ]
      }
    ]
  };

  return schema;
};

interface GenerateAddBookingItemFormSchemaParams {
  venues: Venue[];
  activityFields: Field[];
  prefix: string;
  client: Client;
  handleSubmit: () => void;
  handleCancel: () => void;
}

export const generateAddBookingItemFormSchema = ({
  venues,
  activityFields,
  prefix,
  client,
  handleSubmit,
  handleCancel
}: GenerateAddBookingItemFormSchemaParams): Schema => {
  const schema = {
    fields: [
      {
        component: "admin-booking-items-wizard",
        name: "admin-booking-items-wizard",
        handleSubmit,
        handleCancel,
        fields: [
          {
            name: "activityGroup",
            title: "Select activity",
            nextStep: "tickets",
            fields: [
              {
                index: 1,
                arrayField: false,
                component: "activity-groups-select-one",
                name: `${prefix}.activityGroup`,
                label: "Select activity",
                venues,
                fields: activityFields,
                client,
                helpText:
                  "Field can also be applied to activities when they are created or edited.",
                validate: [
                  {
                    type: "required",
                    message: "Please select an activity"
                  }
                ]
              }
            ]
          },
          {
            name: "tickets",
            title: "Select tickets",
            fields: [
              {
                index: 0,
                arrayField: false,
                component: "admin-booking-activity-ticket-select",
                name: `${prefix}.ticketItems`,
                label: "Select ticket",
                isRequired: true,
                validate: [
                  {
                    type: "required",
                    message: "Please select at least one ticket"
                  }
                ],
                resolveProps: (_props, _field, formOptions) => {
                  const values = formOptions.getState().values[prefix];
                  const activityGroup = values?.activityGroup;
                  return {
                    activityGroupId: activityGroup?._id
                  };
                }
              }
            ]
          }
        ]
      }
    ]
  };

  return schema;
};

export const clearAttendeesValuesOnUserChange = (formState, change) => {
  if (formState.values.bookingItems) {
    const bookingItems: CreateAdminBookingItemsByActivityGroup[] =
      formState.values.bookingItems;
    const updatedBookingItems = bookingItems.map(activityGroupItem => {
      return {
        ...activityGroupItem,
        items: activityGroupItem.items.map(item => ({
          ...item,
          attendees: item.attendees.map(() => ({
            _id: ""
          }))
        }))
      };
    });
    change("bookingItems", updatedBookingItems);
  }
};
